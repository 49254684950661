
import moment from "moment"
import {
    DISCOUNT_DELIVERY_TYPE_MONEY,
    DISCOUNT_DELIVERY_TYPE_PERCENTAGE,
    DISCOUNT_TOTAL_TYPE_MONEY,
    DISCOUNT_TOTAL_TYPE_PERCENTAGE,
    SET_ACTIVE_BENEFIT,
    SET_ADD_BENEFIT,
    SET_ADD_CUSTOM_TIME,
    SET_ADD_PRODUCT,
    SET_CHANGE_CUSTOM_TIME,
    SET_CHANGE_DATE,
    SET_CHANGE_DAYS_WEEK,
    SET_CHANGE_DELIVERY_TYPE,
    SET_CHANGE_PAYMENT_METHOD,
    SET_CHANGE_TAB_TIME,
    SET_CLEAN_PRODUCT,
    SET_CLEAN_RULES,
    SET_CLOSE_MODAL,
    SET_DELETE_BENEFIT,
    SET_DELETE_CUSTOM_TIME,
    SET_DELETE_PRODUCT,
    SET_DELIVERY_DISCOUNT_MONEY,
    SET_DELIVERY_DISCOUNT_PERCENTAGE,
    SET_DELIVERY_DISCOUNT_STOPPER,
    SET_DELIVERY_DISCOUNT_TYPE,
    SET_DELIVERY_TOTAL_MONEY,
    SET_DELIVERY_TOTAL_PERCENTAGE,
    SET_DELIVERY_TOTAL_STOPPER,
    SET_DELIVERY_TOTAL_TYPE,
    SET_INITIAL_DATA,
    SET_OPEN_MODAL,
    SET_QUANTITY_FREE_PRODUCTS,
    SET_RECHARGE,
    SET_SUBTOTAL_DISCOUNT,
    SET_SUBTOTAL_FROM,
    SET_SUBTOTAL_UNTIL,
    SET_TIME,
} from "./types"

export interface IBeneficioCompra {
    Open: boolean,
    SubtotalDiscount: boolean,
    Benefits: IBenefits[],
}

export interface ITime {
    StartTime: string | null,
    EndTime: string | null,
}

export interface IRule {
    Dates: {
        StartDate: string | null,
        EndDate: string | null,
    },
    Delivery: boolean,
    PickUp: boolean,
    DaysWeek: string[],
    CustomTime: boolean,
    Time: ITime[],
    PaymentMethod: number[],
    QuantityRules: number,
}

export interface IBenefits {
    IdBeneficio?: number | null,
    From: number,
    Until: number,
    Recharge: number,
    DiscountDeliveryType: string,
    DiscountDeliveryMoney: number,
    DiscountDeliveryPercentage: number,
    StopperDiscountDeliveryPercentage: number,
    DiscountTotalType: string,
    DiscountTotalMoney: number,
    DiscountTotalPercentage: number,
    StopperDiscountTotalPercentage: number,
    Active: boolean,
    FreeProducts: {
        Quantity: number,
        Products: any[]
    },
    Rules: IRule,
    Delete: boolean,
}

const INIT_STATE = {
    Open: true,
    SubtotalDiscount: true,
    Benefits: [],
}

export default (state: IBeneficioCompra = INIT_STATE, action: any) => {
    switch (action?.type) {

        case SET_OPEN_MODAL: {
            return {
                ...state,
                Open: true,
                Benefits: [],
            }
        }

        case SET_CLOSE_MODAL: {
            return {
                ...state,
                Open: false,
                Benefits: [],
            }
        }

        case SET_INITIAL_DATA: {
            if (action.payload.data.length > 0) {
                state.SubtotalDiscount = action?.payload?.data[0]?.SubtotalConDescuento

                state.Benefits = action.payload.data.map((e: any) => {
                    return {
                        IdBeneficio: e?.IdBeneficio,
                        From: e?.SubtotalDesde,
                        Until: e?.SubtotalHasta,
                        Recharge: 0,
                        DiscountDeliveryType: (e?.DsctoGastoEnvioPorcentaje) ? DISCOUNT_DELIVERY_TYPE_PERCENTAGE : DISCOUNT_DELIVERY_TYPE_MONEY,
                        DiscountDeliveryMoney: (e?.DsctoGastoEnvioPeso) ? e?.DsctoGastoEnvioPeso : 0,
                        DiscountDeliveryPercentage: (e?.DsctoGastoEnvioPorcentaje) ? e?.DsctoGastoEnvioPorcentaje : 0,
                        StopperDiscountDeliveryPercentage: (e?.TopeDsctoGastoEnvioPorcentaje) ? e?.TopeDsctoGastoEnvioPorcentaje : 0,
                        DiscountTotalType: (e?.DsctoTotalPorcentaje) ? DISCOUNT_TOTAL_TYPE_PERCENTAGE : DISCOUNT_TOTAL_TYPE_MONEY,
                        DiscountTotalMoney: (e?.DsctoTotalPeso) ? e?.DsctoTotalPeso : 0,
                        DiscountTotalPercentage: (e?.DsctoTotalPorcentaje) ? e?.DsctoTotalPorcentaje : 0,
                        StopperDiscountTotalPercentage: (e?.TopeDsctoTotalPorcentaje) ? e?.TopeDsctoTotalPorcentaje : 0,
                        Active: e?.Activo,
                        Delete: false,
                        FreeProducts: {
                            Quantity: e?.CantidadProductosGratis,
                            Products: (e?.productos && e.productos.length > 0) ? e?.productos : [],
                        },
                        Rules: {
                            Dates: {
                                StartDate: (e?.reglas?.FechaInicio) ? e?.reglas?.FechaInicio : null,
                                EndDate: (e?.reglas?.FechaFin) ? e?.reglas?.FechaFin : null,
                            },
                            Delivery: (e?.reglas?.Delivery) ? e?.reglas?.Delivery : false,
                            PickUp: (e?.reglas?.Retiro) ? e?.reglas?.Retiro : false,
                            DaysWeek: (e?.reglas?.DiaSemana) ? e?.reglas?.DiaSemana : [],
                            CustomTime: (e?.reglas?.Hora && e.reglas.Hora.length > 0) ? true : false,
                            Time: (e?.reglas?.Hora && e.reglas.Hora.length > 0) ? e.reglas.Hora.map((x: any) => {
                                return {
                                    StartTime: x?.Inicio,
                                    EndTime: x?.Fin,
                                }
                            }) : [],
                            PaymentMethod: (e?.reglas?.MetodoPago && e.reglas.MetodoPago.length > 0) ? e.reglas.MetodoPago.map((x: any) => {
                                return parseInt(x)
                            }) : [],
                            QuantityRules: 0,
                        }
                    }
                })

                state.Benefits = state.Benefits.map((e: any) => {
                    return {
                        ...e,
                        Rules: {
                            ...e?.Rules,
                            QuantityRules: getQuantityRules(e?.Rules)
                        }
                    }
                })
            }

            return {
                ...state,
            }
        }

        case SET_SUBTOTAL_DISCOUNT: {
            return {
                ...state,
                SubtotalDiscount: action?.payload?.subtotalDiscount
            }
        }

        case SET_ADD_BENEFIT: {
            const newBenefit: IBenefits = {
                From: 0,
                Until: 0,
                Recharge: 0,
                DiscountDeliveryType: DISCOUNT_DELIVERY_TYPE_MONEY,
                DiscountDeliveryMoney: 0,
                DiscountDeliveryPercentage: 0,
                StopperDiscountDeliveryPercentage: 0,
                DiscountTotalType: DISCOUNT_TOTAL_TYPE_MONEY,
                DiscountTotalMoney: 0,
                DiscountTotalPercentage: 0,
                StopperDiscountTotalPercentage: 0,
                Active: true,
                Delete: false,
                FreeProducts: {
                    Quantity: 0,
                    Products: []
                },
                Rules: {
                    Dates: {
                        StartDate: moment().format('YYYY-MM-DD'),
                        EndDate: null,
                    },
                    Delivery: true,
                    PickUp: true,
                    DaysWeek: ['L', 'M', 'X', 'J', 'V', 'S', 'D'],
                    CustomTime: false,
                    Time: [],
                    PaymentMethod: [],
                    QuantityRules: 0,
                },
            }

            state?.Benefits.push(newBenefit)

            return {
                ...state,
            }
        }

        case SET_DELETE_BENEFIT: {
            if (state?.Benefits[action?.payload?.position]) {
                state.Benefits[action?.payload?.position].Delete = action?.payload?.active
            }

            return {
                ...state,
            }
        }

        case SET_ACTIVE_BENEFIT: {
            if (state?.Benefits[action?.payload?.position]) {
                state.Benefits[action?.payload?.position].Active = action?.payload?.active
            }

            return {
                ...state,
            }
        }

        case SET_SUBTOTAL_FROM: {
            if (state?.Benefits[action?.payload?.position]) {
                state.Benefits[action?.payload?.position].From = action?.payload?.from

                if (action?.payload?.force && state.Benefits[action?.payload?.position].Until <= action?.payload?.from) {
                    state.Benefits[action?.payload?.position].Until = (action?.payload?.from + 1)
                }
            }

            return {
                ...state,
            }
        }

        case SET_SUBTOTAL_UNTIL: {
            if (state?.Benefits[action?.payload?.position]) {
                state.Benefits[action?.payload?.position].Until = action?.payload?.until

                if (action?.payload?.force && action?.payload?.until <= state.Benefits[action?.payload?.position].From) {
                    state.Benefits[action?.payload?.position].Until = (state.Benefits[action?.payload?.position].From + 1)
                }
            }

            return {
                ...state,
            }
        }

        case SET_RECHARGE: {
            if (state?.Benefits[action?.payload?.position]) {
                state.Benefits[action?.payload?.position].Recharge = action?.payload?.recharge

                // CUANDO APLICA RECARGO, NO APLICAN LOS BENEFICIOS POR ESO SE REINICIAN
                state.Benefits[action?.payload?.position].DiscountDeliveryType = DISCOUNT_DELIVERY_TYPE_MONEY
                state.Benefits[action?.payload?.position].DiscountDeliveryMoney = 0
                state.Benefits[action?.payload?.position].DiscountDeliveryPercentage = 0
                state.Benefits[action?.payload?.position].StopperDiscountDeliveryPercentage = 0
                state.Benefits[action?.payload?.position].DiscountTotalType = DISCOUNT_TOTAL_TYPE_MONEY
                state.Benefits[action?.payload?.position].DiscountTotalMoney = 0
                state.Benefits[action?.payload?.position].DiscountTotalPercentage = 0
                state.Benefits[action?.payload?.position].StopperDiscountTotalPercentage = 0
                state.Benefits[action?.payload?.position].FreeProducts = {
                    Quantity: 0,
                    Products: []
                }
            }

            return {
                ...state,
            }
        }

        case SET_DELIVERY_DISCOUNT_TYPE: {
            if (state?.Benefits[action?.payload?.position]) {
                state.Benefits[action?.payload?.position].DiscountDeliveryType = action?.payload?.type
                state.Benefits[action?.payload?.position].DiscountDeliveryMoney = 0
                state.Benefits[action?.payload?.position].DiscountDeliveryPercentage = 0
                state.Benefits[action?.payload?.position].StopperDiscountDeliveryPercentage = 0
            }

            return {
                ...state,
            }
        }

        case SET_DELIVERY_DISCOUNT_MONEY: {
            if (state?.Benefits[action?.payload?.position]) {
                state.Benefits[action?.payload?.position].DiscountDeliveryMoney = action?.payload?.money
                state.Benefits[action?.payload?.position].DiscountDeliveryPercentage = 0
                state.Benefits[action?.payload?.position].StopperDiscountDeliveryPercentage = 0
            }

            return {
                ...state,
            }
        }

        case SET_DELIVERY_DISCOUNT_PERCENTAGE: {
            if (state?.Benefits[action?.payload?.position]) {
                state.Benefits[action?.payload?.position].DiscountDeliveryMoney = 0
                state.Benefits[action?.payload?.position].DiscountDeliveryPercentage = action?.payload?.percentage
            }

            return {
                ...state,
            }
        }

        case SET_DELIVERY_DISCOUNT_STOPPER: {
            if (state?.Benefits[action?.payload?.position]) {
                state.Benefits[action?.payload?.position].StopperDiscountDeliveryPercentage = action?.payload?.stopper
            }

            return {
                ...state,
            }
        }

        case SET_DELIVERY_TOTAL_TYPE: {
            if (state?.Benefits[action?.payload?.position]) {
                state.Benefits[action?.payload?.position].DiscountTotalType = action?.payload?.type
                state.Benefits[action?.payload?.position].DiscountTotalMoney = 0
                state.Benefits[action?.payload?.position].DiscountTotalPercentage = 0
                state.Benefits[action?.payload?.position].StopperDiscountTotalPercentage = 0
            }

            return {
                ...state,
            }
        }

        case SET_DELIVERY_TOTAL_MONEY: {
            if (state?.Benefits[action?.payload?.position]) {
                state.Benefits[action?.payload?.position].DiscountTotalMoney = action?.payload?.money
                state.Benefits[action?.payload?.position].DiscountTotalPercentage = 0
                state.Benefits[action?.payload?.position].StopperDiscountTotalPercentage = 0
            }

            return {
                ...state,
            }
        }

        case SET_DELIVERY_TOTAL_PERCENTAGE: {
            if (state?.Benefits[action?.payload?.position]) {
                state.Benefits[action?.payload?.position].DiscountTotalMoney = 0
                state.Benefits[action?.payload?.position].DiscountTotalPercentage = action?.payload?.percentage
            }

            return {
                ...state,
            }
        }

        case SET_DELIVERY_TOTAL_STOPPER: {
            if (state?.Benefits[action?.payload?.position]) {
                state.Benefits[action?.payload?.position].StopperDiscountTotalPercentage = action?.payload?.stopper
            }

            return {
                ...state,
            }
        }

        case SET_QUANTITY_FREE_PRODUCTS: {
            if (state?.Benefits[action?.payload?.position]) {
                state.Benefits[action?.payload?.position].FreeProducts.Quantity = action?.payload?.quantity
            }

            return {
                ...state,
            }
        }

        case SET_ADD_PRODUCT: {
            if (state?.Benefits[action?.payload?.position]) {
                const found = state.Benefits[action?.payload?.position].FreeProducts.Products.find((e: any) => e?.IdProducto === action?.payload?.product?.IdProducto)
                if (found) {
                    state.Benefits[action?.payload?.position].FreeProducts.Products = state.Benefits[action?.payload?.position].FreeProducts.Products.filter((e: any) => e?.IdProducto !== found?.IdProducto)
                } else {
                    state.Benefits[action?.payload?.position].FreeProducts.Products.push(action?.payload?.product)
                }
            }

            return {
                ...state,
            }
        }

        case SET_DELETE_PRODUCT: {
            if (state?.Benefits[action?.payload?.position]) {
                state.Benefits[action?.payload?.position].FreeProducts.Products = state.Benefits[action?.payload?.position].FreeProducts.Products.filter((e: any, i: number) => i !== action?.payload?.productPosition)
            }

            return {
                ...state,
            }
        }

        case SET_CLEAN_PRODUCT: {
            if (state?.Benefits[action?.payload?.position]) {
                state.Benefits[action?.payload?.position].FreeProducts = {
                    Quantity: 0,
                    Products: []
                }
            }

            return {
                ...state,
            }
        }

        case SET_CLEAN_RULES: {
            if (state?.Benefits[action?.payload?.position]) {
                state.Benefits[action?.payload?.position].Rules = {
                    Dates: {
                        StartDate: moment().format('YYYY-MM-DD'),
                        EndDate: null,
                    },
                    Delivery: true,
                    PickUp: true,
                    DaysWeek: ['L', 'M', 'X', 'J', 'V', 'S', 'D'],
                    CustomTime: false,
                    Time: [],
                    PaymentMethod: [],
                    QuantityRules: 0,
                }
            }

            return {
                ...state,
            }
        }

        case SET_CHANGE_DATE: {
            if (state?.Benefits[action?.payload?.position]) {
                if (action?.payload?.type === 'START') {
                    state.Benefits[action?.payload?.position].Rules.Dates.StartDate = action?.payload?.date
                } else {
                    state.Benefits[action?.payload?.position].Rules.Dates.EndDate = action?.payload?.date
                }

                state.Benefits[action?.payload?.position].Rules.QuantityRules = getQuantityRules(state?.Benefits[action?.payload?.position].Rules)
            }

            return {
                ...state,
            }
        }

        case SET_CHANGE_DELIVERY_TYPE: {
            if (state?.Benefits[action?.payload?.position]) {
                if (action?.payload?.type === 'DELIVERY') {
                    state.Benefits[action?.payload?.position].Rules.Delivery = !state?.Benefits[action?.payload?.position]?.Rules?.Delivery
                } else {
                    state.Benefits[action?.payload?.position].Rules.PickUp = !state?.Benefits[action?.payload?.position]?.Rules?.PickUp
                }

                state.Benefits[action?.payload?.position].Rules.QuantityRules = getQuantityRules(state?.Benefits[action?.payload?.position].Rules)
            }

            return {
                ...state,
            }
        }

        case SET_CHANGE_DAYS_WEEK: {
            if (state?.Benefits[action?.payload?.position]) {
                const found = state.Benefits[action?.payload?.position].Rules.DaysWeek.find((e: string) => e === action?.payload?.day)

                if (found) {
                    state.Benefits[action?.payload?.position].Rules.DaysWeek = state.Benefits[action?.payload?.position].Rules.DaysWeek.filter((e: string) => e !== action?.payload?.day)
                } else {
                    state.Benefits[action?.payload?.position].Rules.DaysWeek.push(action?.payload?.day)
                }

                state.Benefits[action?.payload?.position].Rules.QuantityRules = getQuantityRules(state?.Benefits[action?.payload?.position].Rules)
            }

            return {
                ...state,
            }
        }

        case SET_CHANGE_TAB_TIME: {
            if (state?.Benefits[action?.payload?.position]) {
                const found = state.Benefits[action?.payload?.position].Rules.Time.find((e: ITime) => e?.StartTime === action?.payload?.start && e?.EndTime === action?.payload?.end)
                if (found) {
                    state.Benefits[action?.payload?.position].Rules.Time = state.Benefits[action?.payload?.position].Rules.Time.filter((e: ITime) => e !== found)
                } else {
                    state.Benefits[action?.payload?.position].Rules.Time.push({ StartTime: action?.payload?.start, EndTime: action?.payload?.end })
                }

                state.Benefits[action?.payload?.position].Rules.QuantityRules = getQuantityRules(state?.Benefits[action?.payload?.position].Rules)
            }

            return {
                ...state,
            }
        }

        case SET_CHANGE_CUSTOM_TIME: {
            if (state?.Benefits[action?.payload?.position]) {
                state.Benefits[action?.payload?.position].Rules.Time = (!state.Benefits[action?.payload?.position].Rules.CustomTime === true) ?
                    [{ StartTime: null, EndTime: null }]
                    :
                    []
                state.Benefits[action?.payload?.position].Rules.CustomTime = !state.Benefits[action?.payload?.position].Rules.CustomTime

                state.Benefits[action?.payload?.position].Rules.QuantityRules = getQuantityRules(state?.Benefits[action?.payload?.position].Rules)
            }

            return {
                ...state,
            }
        }

        case SET_TIME: {
            if (state?.Benefits[action?.payload?.position]) {
                if (state.Benefits[action?.payload?.position].Rules.Time[action?.payload?.timePosition]) {
                    if (action?.payload?.type === 'START') {
                        state.Benefits[action?.payload?.position].Rules.Time[action?.payload?.timePosition].StartTime = action?.payload?.time
                    } else {
                        state.Benefits[action?.payload?.position].Rules.Time[action?.payload?.timePosition].EndTime = action?.payload?.time
                    }
                }

                state.Benefits[action?.payload?.position].Rules.QuantityRules = getQuantityRules(state?.Benefits[action?.payload?.position].Rules)
            }

            return {
                ...state,
            }
        }

        case SET_ADD_CUSTOM_TIME: {
            if (state?.Benefits[action?.payload?.position]) {
                state.Benefits[action?.payload?.position].Rules.Time.push({ StartTime: null, EndTime: null })

                state.Benefits[action?.payload?.position].Rules.QuantityRules = getQuantityRules(state?.Benefits[action?.payload?.position].Rules)
            }

            return {
                ...state,
            }
        }

        case SET_DELETE_CUSTOM_TIME: {
            if (state?.Benefits[action?.payload?.position]) {
                state.Benefits[action?.payload?.position].Rules.Time.pop()
                if (state.Benefits[action?.payload?.position].Rules.Time.length === 0) {
                    state.Benefits[action?.payload?.position].Rules.CustomTime = false
                }

                state.Benefits[action?.payload?.position].Rules.QuantityRules = getQuantityRules(state?.Benefits[action?.payload?.position].Rules)
            }

            return {
                ...state,
            }
        }

        case SET_CHANGE_PAYMENT_METHOD: {
            if (state?.Benefits[action?.payload?.position]) {
                const found = state.Benefits[action?.payload?.position].Rules.PaymentMethod.find((e: number) => e === action?.payload?.paymentMethod)
                if (found) {
                    state.Benefits[action?.payload?.position].Rules.PaymentMethod = state.Benefits[action?.payload?.position].Rules.PaymentMethod.filter((e: number) => e !== found)
                } else {
                    state.Benefits[action?.payload?.position].Rules.PaymentMethod.push(action?.payload?.paymentMethod)
                }

                state.Benefits[action?.payload?.position].Rules.QuantityRules = getQuantityRules(state?.Benefits[action?.payload?.position].Rules)

            }

            return {
                ...state,
            }
        }

        default:
            return state
    }
}

const getQuantityRules = (rules: IRule) => {
    let quantity: number = 0

    if (rules?.Dates?.EndDate) {
        quantity++
    }

    if ((rules?.Delivery && !rules?.PickUp) || (!rules?.Delivery && rules?.PickUp)) {
        quantity++
    }

    if (rules?.DaysWeek.length > 0 && rules?.DaysWeek.length < 7) {
        quantity++
    }

    if (rules?.CustomTime && rules?.Time.length > 0) {
        quantity++
    }

    if (!rules?.CustomTime && rules?.Time.length > 0 && rules?.Time.length < 3) {
        quantity++
    }

    if (rules?.PaymentMethod.length > 0) {
        quantity++
    }

    return quantity
}
