import styled from "styled-components";
import {grey} from "@material-ui/core/colors";

export const ClientesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  // border: 1px solid red;
  padding: 10px;
`;

export const ClientesBody = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  // border: 1px solid crimson;
`;

export const PaginatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export const ClientesHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  // border: 1px solid magenta;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 3px;
  background-color: white;
`;

export const NavClientes = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100px;
  // border: 2px solid orange;
  padding: 10px;

  @media screen and (max-width: 1024px){
    display: none;
  }
`;


export const ListadoClientes = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  // border: 2px solid green;
  padding: 10px;
  
  height: 58vh;
  overflow-x: scroll;
`;


export const InfoContainer = styled.div`
  
    display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  
  
    & p{
      margin-top: 4px;
      margin-bottom: 4px;
      text-align: center;
    }
  
  & .close {
    margin-top: 20px;
  }
  
  & .map{
    margin-top: 20px;
    margin-bottom: 10px;
  }
`;

export const LoadingClientes = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  & .image-container {
    
    border-radius: 20px;
    overflow: hidden;
    & img{
      max-width: 320px;
    }
  }
  
  & .title{
    font-size: 18px;
    color: ${grey[500]};
  }
  
`;


export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const NoClientes =  styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  
  & img{
    max-width: 300px;
  }
`;