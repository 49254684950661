// **************** ROUTE CONSTANT START **************************
// General Page Section
export const DASHBOARD = '/'
export const PRODUCTS = '/products'
export const BRANDS = '/brands'
export const CATEGORY = '/category'

export const LOGOUT = '/logout'
export const ORDERS = '/orders'
export const CUSTOMERS = '/customers'
export const CLIENTES = '/clientes'
export const IMPORTAR_CLIENTES = '/importar-clientes'
export const POINTS_PROGRAM = '/points-program'
export const COUPONS = '/coupons'
export const SETTINGS = '/settings'
export const CONFIG = '/finance-config'
export const USUARIO_PERFIL = '/usuario-perfil'
export const STUFF_MEMBERS = '/staff-members'
export const USERS = '/users'
export const SCHEDULES = '/schedules'
export const SITE_SETTINGS = '/site-settings'
export const SELLS = '/sells'
export const PRODUCTS_INSIGHTS = "/insights/products"
export const FINANCE = '/finance'
export const REPORTS = '/reports'
export const INVOICES = '/invoices'
export const INVOICE_DETAIL = '/invoices/:IdSucursalDeposito'
export const MENU = '/menu'
export const OPCION = '/opcion'
export const GEOZONA = '/geozona/:IdSucursal'
export const FAVOURITE_RIDERS = '/riders/:IdSucursal'
export const PRODUCTS_PROMOTIONS = '/products-promotions'
export const STORE_DESIGN = '/store-design'
export const REGISTRO_COMERCIO = '/registro'
export const CUPONES = '/cupones'
export const PAYMENTDETAILS = '/paymentdetails/'
export const WEB = '/footer'
export const FOOTER = '/footer'
export const CONFI_SHOP = '/shop-configuracion'
export const PAGINAS_SHOP = '/page'
export const SUCURSAL_MESAS = '/sucursal-mesas'
export const LOGS_USER = '/logs'
export const NFC_ELEMENTOS = '/nfc-elementos'
export const NFC_TRABAJADORES = '/nfc-trabajadores'
export const NFC_INSPECCIONES = '/nfc-inspecciones'
export const COMPONENT_PAGE = '/page/component/:IdPage'

export const MARKETING = '/marketing'
export const MARKETING_PLANTILLA = '/mail-template'
export const MARKETING_AUDIENCIA = '/audiencia'
export const MARKETING_CAMPANIA = '/campania'
export const MARKETING_AUTOMATIZACION = '/automatizacion'
export const MARKETING_SALEBOT = '/saleBot'

export const FORMULARIO = '/form'

export const SALESETUP = '/saleSetup'


//market
export const MARKETPLACES = '/marketplace'
export const MARKETPLACES_PRODUCT = '/marketplace-product'
export const MARKETPLACES_AJUSTES = '/marketplace-setting'

export const CONFIG_POS = '/settings-pos'

export const PRODUCTO_PROMOCIONES = '/producto-promociones'
export const PRODUCTO_SINCRONIZACION = '/sincronizar-productos'
export const BENEFICIOS_COMPRA = '/beneficios-compra'
// **************** ROUTE CONSTANT END **************************

