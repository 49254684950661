import styled from "styled-components";
import {grey, yellow} from "@material-ui/core/colors";

export const Loading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  & .image-container {
    
    border-radius: 20px;
    overflow: hidden;
    & img{
      max-width: 320px;
    }
  }
  
  & .title{
    font-size: 18px;
    color: ${grey[500]};
  }
  
`;

