export const ID_TIPO_AGRUPACION_PRODUCTOS = 1
export const ID_TIPO_AGRUPACION_CASHBACK = 2

export const ID_TIPO_DELIVERY = 1
export const ID_TIPO_RETIRO = 2

export const ID_TIPO_ANTES_POSIBLE = 1
export const ID_TIPO_PROGRAMADA = 3

export const SET_TYPE = 'SET_TYPE'
export const SET_NAME = 'SET_NAME'
export const SET_RESET = 'SET_RESET'
export const SET_GETTING_DATA = 'SET_GETTING_DATA'
export const SET_USE_COLOR = 'SET_USE_COLOR'
export const SET_COLOR = 'SET_COLOR'
export const SET_OPACITY = 'SET_OPACITY'
export const SET_DESCRIPCION = 'SET_DESCRIPCION'
export const SET_IMAGENES = 'SET_IMAGENES'

export const SET_ADD_PRODUCT = 'SET_ADD_PRODUCT'
export const SET_DELETE_PRODUCT = 'SET_DELETE_PRODUCT'
export const SET_PERCENTAGE_PROMO = 'SET_PERCENTAGE_PROMO'
export const SET_MINIMUN_SIZE = 'SET_MINIMUN_SIZE'
export const SET_START_DATE = 'SET_START_DATE'
export const SET_END_DATE = 'SET_END_DATE'
export const SET_DAYS_WEEK = 'SET_DAYS_WEEK'
export const SET_ALL_DAY = 'SET_ALL_DAY'
export const SET_START_TIME = 'SET_START_TIME'
export const SET_END_TIME = 'SET_END_TIME'
export const SET_DELIVERY_TYPE = 'SET_DELIVERY_TYPE'
export const SET_DELIVERY_DATE_TYPE = 'SET_DELIVERY_DATE_TYPE'
export const SET_FREE_DELIVERY = 'SET_FREE_DELIVERY'
export const SET_DELIVERY_SIZE = 'SET_DELIVERY_SIZE'
export const SET_STOCK = 'SET_STOCK'
export const SET_STOCK_SIZE = 'SET_STOCK_SIZE'

export const SET_ADD_CASHBACK = 'SET_ADD_CASHBACK'
export const SET_DELETE_CASHBACK = 'SET_DELETE_CASHBACK'
export const SET_SIZE_ORDERS = 'SET_SIZE_ORDERS'
export const SET_MINIMUN_PRICE = 'SET_MINIMUN_PRICE'
export const SET_GIFT_POINTS = 'SET_GIFT_POINTS'