import Axios from 'axios';
import { all, takeEvery, fork, put, call } from 'redux-saga/effects';
import types from './types'

import { LAMBDA_AUTH } from '../../config'

function* loginWithToken() {
    yield takeEvery(types.LOGIN_WITH_TOKEN, function* ({ payload, callback }) {
        const { Token } = payload

        const response = yield call(callLoginWithToken, Token)

        if (response) {
            yield put({
                type: types.AUTHENTICATE_SUCCESS,
                payload: response.data
            })
        }
        callback && callback()
    });
}

function callLoginWithToken(Token) {
    return Axios.post(`${LAMBDA_AUTH}/loginWithToken`, {}, {
        headers: {
            Authorization: `Bearer ${Token}`
        }
    }).then(response => {
        const LastIdSucursal = Number(localStorage.getItem('LastIdSucursal'))
        const CurrentProfile = localStorage.getItem('CurrentProfile') || null
        const LastCodeMarketplace = localStorage.getItem('LastCodeMarketplace')

        let usuarioPerfil = null
        if (CurrentProfile) {
            usuarioPerfil = response.data.payload.usuarioPerfiles.find(up => up.sucursal.IdSucursal === LastIdSucursal && up.perfil.Perfil === CurrentProfile) || response.data.payload.usuarioPerfiles[0]

        } else {

            usuarioPerfil = response.data.payload.usuarioPerfiles.find(up => up.sucursal.IdSucursal === LastIdSucursal) || response.data.payload.usuarioPerfiles[0]
        }

        let market = []
        if (usuarioPerfil.market && usuarioPerfil.market.length > 0) {
            market = usuarioPerfil.market.find(mar => mar.Codigo === LastCodeMarketplace) || usuarioPerfil.market[0]

            localStorage.setItem('LastCodeMarketplace', market.Codigo)

        }

        localStorage.setItem('LastIdSucursal', usuarioPerfil.sucursal.IdSucursal)
        localStorage.setItem('CurrentProfile', usuarioPerfil.perfil.Perfil)
        document.title = usuarioPerfil.sucursal.Titulo

        const privilegiosPerfil = response.data.payload.privilegiosPerfiles.find(p => p.IdPerfil === usuarioPerfil.perfil.IdPerfil)

        response.data.payload = {
            ...response.data.payload,
            usuarioPerfil,
            privilegiosPerfil,
            market
        }
        response.data.Token = Token

        return response
    }).catch(console.error)

}

function* authenticate() {
    yield takeEvery(types.AUTHENTICATE, function* ({ payload, callback }) {
        const newAuth = payload

        document.title = newAuth.payload.usuarioPerfil.sucursal.Titulo

        yield put({
            type: types.AUTHENTICATE_SUCCESS,
            payload: payload
        })
        callback && callback()
    });
}


function* logout() {
    yield takeEvery(types.LOGOUT, function* ({ payload, callback }) {
        const { Token } = payload
        document.title = 'Administrador Comercio'
        call(callLogout, Token)
        yield put({
            type: types.LOGOUT_SUCCESS,
            payload: payload
        })
        callback && callback()
    });
}

function callLogout(Token) {
    Axios.post(`${LAMBDA_AUTH}/logout`, {

    }, {
        headers: {
            Authorization: `Bearer ${Token}`
        }
    })
        .then(console.log)
        .catch(console.error)
}

export default function* rootSaga() {
    yield all([
        fork(loginWithToken),
        fork(authenticate),
        fork(logout)
    ]);
}