import React, { useEffect, useState } from 'react'
import {
    InfoContainer,
} from "./index.style";
import axios from "axios";
import {LAMBDA_COMMON} from "../../../config"
import { useSelector } from 'react-redux'
import {
    Avatar,
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, TextField
} from "@material-ui/core";
import { Button, IconButton,CircularProgress } from "@material-ui/core/";
import currencyFormatter from 'currency-formatter';
import currencyFormatterOptions from '../../../config/currencyFormatterOptions.json'

const PopupOrderDetail = ({onClose, open, id}) => {

    const auth = useSelector(state => state.auth);

    const [order,setOrder] = useState(null)
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(false)

    useEffect(()=>{
        if(open && id!==null){
        
            setLoading(true);
            setError(false);
            axios.get(`${LAMBDA_COMMON}/getOrderDW/${id}`, {
                headers: { 'content-type': 'application/json' },
            })
                .then((response) => {
    

                    setOrder(response.data.order);
                        
                }).catch((err) => {
    
                    setError(true);
    
                }).finally(() => {
                    setLoading(false);
                })
        }
    },[open,id])

    const f = (value)=>{
        return currencyFormatter.format(value, currencyFormatterOptions[auth.payload.usuarioPerfil.sucursal.comercio.nacionalidad.Moneda])
    }

    return (<Dialog open={open} onClose={onClose} fullWidth>

                {error && <div>error</div>}

                {(loading&&!error) && <div style={{minHeight:400,justifyContent:'center',display:'flex',flexDirection:'column',alignItems: 'center'}}><CircularProgress size={40} /></div>}
                
                {(loading===false && !error) && <div style={{minHeight:400}}>
                <DialogTitle>Pedido: <b>{`${order.Codigo}-${order.Correlativo}`}</b></DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "left", flexDirection: "column"}}>
                        <InfoContainer style={{ alignItems: "flex-start" }}>
                            <p>Pasarela de pago: <b>{order.MedioPagoNombre}</b></p>
                            <p>SubTotal: <b>{f(order.SubTotalProductos)}</b></p>
                            <p>Delivery: <b>{f(order.DeliveryCharge)}</b></p>
                            <p>Dirección: <b>{order.DireccionCliente}</b></p>
                            <p>Tipo de Entrega: <b>{order.TipoEntrega}</b></p>
                        </InfoContainer>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column",marginTop:20}}>
                        <Button className={"close"} variant={"outlined"} onClick={onClose}>Cerrar</Button>
                    </div>
                </DialogContent>
                </div>}
                
            </Dialog>
    )
}

export default PopupOrderDetail;