import types from "./types"

const pay = localStorage.getItem("paymentDetail");
const paymentDetail = JSON.parse(pay)

const INITIAL_STATE = {
    paymentDetail: paymentDetail ? paymentDetail : []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.LOAD_STATE_SESSIONSTORAGE:
            return { ...action.payload };
        case types.SET_PAYMENT_DETAIL: {
            localStorage.setItem('paymentDetail', JSON.stringify(action.payload))
            state.paymentDetail = action.payload
            return {
                ...state
            }
        }
        default:
            return state
    }
}
