import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@material-ui/core";
import {ArrowBack} from "@material-ui/icons";
import axios from "axios";
import config from "../../config"
import styled from 'styled-components'
import {InsightLoading} from "./ProductsInsights.style";

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  justify-content: center;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
`;

const TitleSection = styled.h3`

`

const ProductDetailModal = ({open, product, onClose}) => {

    const [loading, setLoading] = useState(false);
    const [productosRelacionados, setProductosRelacionados] = useState([])
    const [topClientes, setTopClientes] = useState([])


    useEffect(() => {
        if(product){
            getDetail()
        }
    }, [product])



    const getDetail = async (resetPage = false) => {
        setLoading(true);

        const response = await axios.get(`${config.LAMBDA_ALIADOS_13489AA}/insights/getProductInsights/${product.IdProducto}`);
        const {
            productosRelacionados,
            topClientes
        } = response.data;

        setProductosRelacionados(productosRelacionados)
        setTopClientes(topClientes)
        setLoading(false);
    }

    if(!product){
        return null
    }

    return (
        <Dialog open={open} onClose={onClose} fullScreen>
            <DialogTitle>
                <IconButton onClick={onClose}><ArrowBack /></IconButton> Detalle del producto {decodeURIComponent(product.Nombre)}
            </DialogTitle>
            <DialogContent>
                {
                    loading &&
                        <MainContainer>
                            <InsightLoading>
                                <div className={"image-container"}>
                                    <img src={"https://s3.us-east-2.amazonaws.com/agil.public/general/search_products.gif"} />
                                </div>
                                <p className={"title"}>Cargando Detalle del Producto ...</p>
                            </InsightLoading>
                        </MainContainer>
                }
                { !loading &&
                <MainContainer>
                    <SectionContainer>
                        <TitleSection>Productos Relacionados</TitleSection>
                        <p>Los clientes habitualmente acompañan la compra de {decodeURIComponent(product.Nombre)} con uno de los siguientes productos.</p>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Imagen</TableCell>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell>Cantidad de Coincidencias</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    productosRelacionados.map((p) => {


                                        return (
                                            <TableRow key={`rel-${p.IdProductoRelacionado}`}>
                                                <TableCell><Avatar src={p.ImagenXS || p.Imagen}/></TableCell>
                                                <TableCell>{decodeURIComponent(p.Nombre)}</TableCell>
                                                <TableCell>{p.Match}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </SectionContainer>
                    <SectionContainer>
                        <TitleSection>Principales Clientes</TitleSection>
                        <p>Los clientes que más solicitan este producto son los siguientes.</p>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Avatar</TableCell>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell>Cantidad de Pedidos</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    topClientes.map((c) => {


                                        return (
                                            <TableRow key={`rel-${c.IdCliente}`}>
                                                <TableCell><Avatar src={c.Imagen}/></TableCell>
                                                <TableCell>{c.Nombre} {c.Apellido}</TableCell>
                                                <TableCell>{c.CantidadPedidos}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </SectionContainer>
                </MainContainer>
                }

            </DialogContent>
        </Dialog>
    )
}

export default ProductDetailModal;