// CONSTANTS
export const DISCOUNT_DELIVERY_TYPE_MONEY = 'DISCOUNT_DELIVERY_TYPE_MONEY'
export const DISCOUNT_DELIVERY_TYPE_PERCENTAGE = 'DISCOUNT_DELIVERY_TYPE_PERCENTAGE'

export const DISCOUNT_TOTAL_TYPE_MONEY = 'DISCOUNT_TOTAL_TYPE_MONEY'
export const DISCOUNT_TOTAL_TYPE_PERCENTAGE = 'DISCOUNT_TOTAL_TYPE_PERCENTAGE'

// REDUX
export const SET_OPEN_MODAL = 'SET_OPEN_MODAL'
export const SET_CLOSE_MODAL = 'SET_CLOSE_MODAL'
export const SET_SUBTOTAL_DISCOUNT = 'SET_SUBTOTAL_DISCOUNT'
export const SET_ADD_BENEFIT = 'SET_ADD_BENEFIT'
export const SET_DELETE_BENEFIT = 'SET_DELETE_BENEFIT'
export const SET_ACTIVE_BENEFIT = 'SET_ACTIVE_BENEFIT'
export const SET_SUBTOTAL_FROM = 'SET_SUBTOTAL_FROM'
export const SET_SUBTOTAL_UNTIL = 'SET_SUBTOTAL_UNTIL'
export const SET_RECHARGE = 'SET_RECHARGE'
export const SET_DELIVERY_DISCOUNT_TYPE = 'SET_DELIVERY_DISCOUNT_TYPE'
export const SET_DELIVERY_DISCOUNT_MONEY = 'SET_DELIVERY_DISCOUNT_MONEY'
export const SET_DELIVERY_DISCOUNT_PERCENTAGE = 'SET_DELIVERY_DISCOUNT_PERCENTAGE'
export const SET_DELIVERY_DISCOUNT_STOPPER = 'SET_DELIVERY_DISCOUNT_STOPPER'
export const SET_DELIVERY_TOTAL_TYPE = 'SET_DELIVERY_TOTAL_TYPE'
export const SET_DELIVERY_TOTAL_MONEY = 'SET_DELIVERY_TOTAL_MONEY'
export const SET_DELIVERY_TOTAL_PERCENTAGE = 'SET_DELIVERY_TOTAL_PERCENTAGE'
export const SET_DELIVERY_TOTAL_STOPPER = 'SET_DELIVERY_TOTAL_STOPPER'
export const SET_QUANTITY_FREE_PRODUCTS = 'SET_QUANTITY_FREE_PRODUCTS'
export const SET_ADD_PRODUCT = 'SET_ADD_PRODUCT'
export const SET_DELETE_PRODUCT = 'SET_DELETE_PRODUCT'
export const SET_CLEAN_PRODUCT = 'SET_CLEAN_PRODUCT'
export const SET_CLEAN_RULES = 'SET_CLEAN_RULES'
export const SET_CHANGE_DATE = 'SET_CHANGE_DATE'
export const SET_CHANGE_DELIVERY_TYPE = 'SET_CHANGE_DELIVERY_TYPE'
export const SET_CHANGE_DAYS_WEEK = 'SET_CHANGE_DAYS_WEEK'
export const SET_CHANGE_CUSTOM_TIME = 'SET_CHANGE_CUSTOM_TIME'
export const SET_CHANGE_TAB_TIME = 'SET_CHANGE_TAB_TIME'
export const SET_TIME = 'SET_TIME'
export const SET_ADD_CUSTOM_TIME = 'SET_ADD_CUSTOM_TIME'
export const SET_DELETE_CUSTOM_TIME = 'SET_DELETE_CUSTOM_TIME'
export const SET_CHANGE_PAYMENT_METHOD = 'SET_CHANGE_PAYMENT_METHOD'
export const SET_INITIAL_DATA = 'SET_INITIAL_DATA'