import moment from "moment"
import {
    ID_TIPO_AGRUPACION_PRODUCTOS,
    ID_TIPO_ANTES_POSIBLE,
    ID_TIPO_DELIVERY,
    ID_TIPO_PROGRAMADA,
    ID_TIPO_RETIRO,
    SET_ADD_CASHBACK,
    SET_ADD_PRODUCT,
    SET_ALL_DAY,
    SET_COLOR,
    SET_DAYS_WEEK,
    SET_DELETE_CASHBACK,
    SET_DELETE_PRODUCT,
    SET_DELIVERY_DATE_TYPE,
    SET_DELIVERY_SIZE,
    SET_DELIVERY_TYPE,
    SET_DESCRIPCION,
    SET_END_DATE,
    SET_END_TIME,
    SET_FREE_DELIVERY,
    SET_GETTING_DATA,
    SET_GIFT_POINTS,
    SET_IMAGENES,
    SET_MINIMUN_PRICE,
    SET_MINIMUN_SIZE,
    SET_NAME,
    SET_OPACITY,
    SET_PERCENTAGE_PROMO,
    SET_RESET,
    SET_SIZE_ORDERS,
    SET_START_DATE,
    SET_START_TIME,
    SET_STOCK,
    SET_STOCK_SIZE,
    SET_TYPE,
    SET_USE_COLOR,
} from "./types"

const INIT_STATE = {
    type: null,
    name: null,
    useColor: false,
    descripcion: null,
    color: '',
    opacity: 100,
    currentTotalPrizeRetiro: 0,
    currentTotalPrizeDelivery: 0,
    promotionTotalPrizeRetiro: 0,
    promotionTotalPrizeDelivery: 0,
    products: [],
    totalPedidos: 0,
    totalSubtotal: 0,
    totalPuntos: 0,
    cashback: [],
    imagenes: [],
    rules: {
        startDate: null,
        endDate: null,
        daysWeek: ['L', 'M', 'X', 'J', 'V', 'S', 'D'],
        allDay: true,
        startTime: null,
        endTime: null,
        deliveryType: [ID_TIPO_DELIVERY, ID_TIPO_RETIRO],
        deliveryDateType: [ID_TIPO_ANTES_POSIBLE, ID_TIPO_PROGRAMADA],
        freeDelivery: false,
        percentageDelivery: null,
        stock: false,
        stockSize: 0,
    },
}

export default (state: any = INIT_STATE, action: any) => {
    switch (action?.type) {

        case SET_TYPE: {
            return {
                ...state,
                type: action?.payload
            }
        }

        case SET_DESCRIPCION: {
            return {
                ...state,
                descripcion: action?.payload
            }
        }

        case SET_IMAGENES: {
            return {
                ...state,
                imagenes: action?.payload
            }
        }

        case SET_NAME: {
            return {
                ...state,
                name: action?.payload
            }
        }

        case SET_ADD_PRODUCT: {
            const data = {
                ...action?.payload?.data,
                PorcentajePromo: 0,
                PrecioPromocionRetiro: action?.payload?.data?.PrecioFinalRetiro,
                PrecioPromocionDelivery: action?.payload?.data?.PrecioFinalDelivery,
                CantidadMinima: 1,
            }

            if (state?.products[action?.payload?.position]) {
                state.products[action?.payload?.position] = data
            } else {
                state.products.splice(action?.payload?.position, 0, data)
            }
            const total = calculateTotalProducts(state)

            return {
                ...state,
                ...total
            }
        }

        case SET_DELETE_PRODUCT: {
            state.products.splice(action?.payload, 1)
            const total = calculateTotalProducts(state)

            return {
                ...state,
                ...total
            }
        }

        case SET_PERCENTAGE_PROMO: {
            const dsctoRetiro = Math.round((state.products[action?.payload?.position].PrecioFinalRetiro - (state.products[action?.payload?.position].PrecioFinalRetiro * (action?.payload?.data / 100))))
            const dsctoDelivery = Math.round((state.products[action?.payload?.position].PrecioFinalDelivery - (state.products[action?.payload?.position].PrecioFinalDelivery * (action?.payload?.data / 100))))

            if (state?.products[action?.payload?.position]) {
                state.products[action?.payload?.position].PorcentajePromo = action?.payload?.data
                state.products[action?.payload?.position].PrecioPromocionRetiro = dsctoRetiro
                state.products[action?.payload?.position].PrecioPromocionDelivery = dsctoDelivery
            }
            const total = calculateTotalProducts(state)

            return {
                ...state,
                ...total
            }
        }

        case SET_MINIMUN_SIZE: {
            if (state?.products[action?.payload?.position]) {
                state.products[action?.payload?.position].CantidadMinima = (action?.payload?.data === null || action?.payload?.data === '' || action?.payload?.data < 1) ? 1 : action?.payload?.data
            }
            const total = calculateTotalProducts(state)

            return {
                ...state,
                ...total
            }
        }

        case SET_START_DATE: {
            state.rules = { ...state.rules, startDate: action?.payload }
            return {
                ...state,
            }
        }

        case SET_END_DATE: {
            state.rules = { ...state.rules, endDate: action?.payload }
            return {
                ...state,
            }
        }

        case SET_DAYS_WEEK: {
            if (state.rules.daysWeek.includes(action?.payload)) {
                state.rules.daysWeek = state.rules.daysWeek.filter(a => a !== action?.payload)
            } else {
                state.rules.daysWeek.push(action?.payload)
            }
            return {
                ...state,
            }
        }

        case SET_ALL_DAY: {
            state.rules = {
                ...state.rules,
                allDay: !state.rules.allDay,
                startTime: (!state.rules.allDay) ? null : state.rules.startTime,
                endTime: (!state.rules.allDay) ? null : state.rules.endTime,
            }
            return {
                ...state,
            }
        }

        case SET_START_TIME: {
            state.rules = { ...state.rules, startTime: action?.payload }
            return {
                ...state,
            }
        }

        case SET_END_TIME: {
            state.rules = { ...state.rules, endTime: action?.payload }
            return {
                ...state,
            }
        }

        case SET_DELIVERY_TYPE: {
            state.rules = { ...state.rules, deliveryType: action?.payload }
            return {
                ...state,
            }
        }

        case SET_DELIVERY_DATE_TYPE: {
            state.rules = { ...state.rules, deliveryDateType: action?.payload }
            return {
                ...state,
            }
        }

        case SET_FREE_DELIVERY: {
            state.rules = {
                ...state.rules,
                freeDelivery: !state.rules.freeDelivery,
                percentageDelivery: (!state.rules.freeDelivery) ? 100 : 0,
            }
            return {
                ...state,
            }
        }

        case SET_STOCK: {
            state.rules = {
                ...state.rules,
                stock: !state.rules.stock,
                stockSize: (!state.rules.freeDelivery) ? 0 : state.rules.stockSize,
            }
            return {
                ...state,
            }
        }

        case SET_STOCK_SIZE: {
            state.rules = {
                ...state.rules,
                stockSize: (action?.payload === null || action?.payload === '' || action?.payload < 1) ? 1 : action?.payload,
            }
            return {
                ...state,
            }
        }

        case SET_RESET: {
            state = INIT_STATE
            state.products = []
            state.cashback = []
            return {
                ...state,
            }
        }

        case SET_ADD_CASHBACK: {
            const data = {
                CantidadPedidos: 1,
                SubtotalMinimo: 1000,
                CantidadPuntos: 100,
            }

            if (state?.cashback[action?.payload]) {
                state.cashback[action?.payload] = data
            } else {
                state.cashback.splice(action?.payload, 0, data)
            }
            const total = calculateTotalCashback(state)

            return {
                ...state,
                ...total
            }
        }

        case SET_DELETE_CASHBACK: {
            state.cashback.splice(action?.payload, 1)
            const total = calculateTotalCashback(state)

            return {
                ...state,
                ...total
            }
        }

        case SET_SIZE_ORDERS: {
            if (state?.cashback[action?.payload?.position]) {
                state.cashback[action?.payload?.position].CantidadPedidos = (action?.payload?.data === null || action?.payload?.data === '' || action?.payload?.data < 1) ? 1 : action?.payload?.data
            }
            const total = calculateTotalCashback(state)

            return {
                ...state,
                ...total
            }
        }

        case SET_MINIMUN_PRICE: {
            if (state?.cashback[action?.payload?.position]) {
                state.cashback[action?.payload?.position].SubtotalMinimo = (action?.payload?.data === null || action?.payload?.data === '' || action?.payload?.data < 1) ? 1 : action?.payload?.data
            }
            const total = calculateTotalCashback(state)

            return {
                ...state,
                ...total
            }
        }

        case SET_GIFT_POINTS: {
            if (state?.cashback[action?.payload?.position]) {
                state.cashback[action?.payload?.position].CantidadPuntos = (action?.payload?.data === null || action?.payload?.data === '' || action?.payload?.data < 1) ? 1 : action?.payload?.data
            }
            const total = calculateTotalCashback(state)

            return {
                ...state,
                ...total
            }
        }

        case SET_GETTING_DATA: {
            if (action?.payload?.tipo === ID_TIPO_AGRUPACION_PRODUCTOS) {
                const recoveryProducts = []

                action.payload.productos.map((e) => {
                    const dsctoRetiro = Math.round((e?.producto?.PrecioFinalRetiro - (e?.producto?.PrecioFinalRetiro * (e?.PorcPrecioPromocion / 100))))
                    const dsctoDelivery = Math.round((e?.producto?.PrecioFinalDelivery - (e?.producto?.PrecioFinalDelivery * (e?.PorcPrecioPromocion / 100))))

                    recoveryProducts.push({
                        ...e?.producto,
                        PorcentajePromo: e?.PorcPrecioPromocion,
                        PrecioPromocionRetiro: dsctoRetiro,
                        PrecioPromocionDelivery: dsctoDelivery,
                        CantidadMinima: e?.Cantidad,
                    })
                })

                state = {
                    type: action?.payload?.tipo,
                    name: action?.payload?.agrupacion?.nombre,
                    descripcion: action?.payload?.agrupacion?.Descripcion,
                    useColor: (action?.payload?.agrupacion?.Diseno && action?.payload?.agrupacion?.Diseno?.Color && action?.payload?.agrupacion?.Diseno?.Color !== '') ? true : false,
                    color: (action?.payload?.agrupacion?.Diseno && action?.payload?.agrupacion?.Diseno?.Color && action?.payload?.agrupacion?.Diseno?.Color !== '') ? action?.payload?.agrupacion?.Diseno?.Color : '',
                    opacity: (action?.payload?.agrupacion?.Diseno && action?.payload?.agrupacion?.Diseno?.Opacidad) ? parseInt(action?.payload?.agrupacion?.Diseno?.Opacidad) : 100,
                    currentTotalPrizeRetiro: 0,
                    currentTotalPrizeDelivery: 0,
                    promotionTotalPrizeRetiro: 0,
                    promotionTotalPrizeDelivery: 0,
                    products: recoveryProducts,
                    totalPedidos: 0,
                    totalSubtotal: 0,
                    totalPuntos: 0,
                    cashback: [],
                    imagenes: action?.payload?.imagenes,
                    rules: {
                        startDate: moment(action?.payload?.reglas?.fechaInicio).utc(false).format('YYYY-MM-DD'),
                        endDate: (action?.payload?.reglas?.fechaFin) ? moment(action?.payload?.reglas?.fechaFin).utc(false).format('YYYY-MM-DD') : null,
                        daysWeek: action?.payload?.reglas?.diasSemana,
                        allDay: (action?.payload?.reglas?.HoraInicio) ? false : true,
                        startTime: (action?.payload?.reglas?.HoraInicio) ? action?.payload?.reglas?.HoraInicio.substring(0, action?.payload?.reglas?.HoraInicio.length - 3) : null,
                        endTime: (action?.payload?.reglas?.HoraFin) ? action?.payload?.reglas?.HoraFin.substring(0, action?.payload?.reglas?.HoraFin.length - 3) : null,
                        deliveryType: (action?.payload?.reglas?.tipoEntrega) ? [action?.payload?.reglas?.tipoEntrega] : [ID_TIPO_DELIVERY, ID_TIPO_RETIRO],
                        deliveryDateType: (action?.payload?.reglas?.IdTipoFechaEntrega) ? [action?.payload?.reglas?.IdTipoFechaEntrega] : [ID_TIPO_ANTES_POSIBLE, ID_TIPO_PROGRAMADA],
                        freeDelivery: (action?.payload?.reglas?.porcDelivery === 100) ? true : false,
                        percentageDelivery: action?.payload?.reglas?.porcDelivery,
                        stock: action?.payload?.reglas?.stockPorUsuario,
                        stockSize: action?.payload?.reglas?.stock,
                    },
                }

                const total = calculateTotalProducts(state)

                state = {
                    ...state,
                    ...total
                }
            } else {
                const recoveryCashback = []
                action.payload.cashback.map((e) => {
                    recoveryCashback.push({
                        CantidadPedidos: e?.CantidadPedidos,
                        SubtotalMinimo: e?.SubtotalMinimo,
                        CantidadPuntos: e?.PuntosRegalo,
                    })
                })

                state = {
                    type: action?.payload?.tipo,
                    name: action?.payload?.agrupacion?.nombre,
                    useColor: false,
                    color: '',
                    opacity: 100,
                    currentTotalPrizeRetiro: 0,
                    currentTotalPrizeDelivery: 0,
                    promotionTotalPrizeRetiro: 0,
                    promotionTotalPrizeDelivery: 0,
                    products: [],
                    totalPedidos: 0,
                    totalSubtotal: 0,
                    totalPuntos: 0,
                    cashback: recoveryCashback,
                    rules: {
                        startDate: moment(action?.payload?.reglas?.fechaInicio).format('YYYY-MM-DD'),
                        endDate: (action?.payload?.reglas?.fechaFin) ? moment(action?.payload?.reglas?.fechaFin).format('YYYY-MM-DD') : null,
                        daysWeek: action?.payload?.reglas?.diasSemana,
                        allDay: (action?.payload?.reglas?.HoraInicio) ? false : true,
                        startTime: (action?.payload?.reglas?.HoraInicio) ? action?.payload?.reglas?.HoraInicio.substring(0, action?.payload?.reglas?.HoraInicio.length - 3) : null,
                        endTime: (action?.payload?.reglas?.HoraFin) ? action?.payload?.reglas?.HoraFin.substring(0, action?.payload?.reglas?.HoraFin.length - 3) : null,
                        deliveryType: [ID_TIPO_DELIVERY, ID_TIPO_RETIRO],
                        deliveryDateType: [ID_TIPO_ANTES_POSIBLE, ID_TIPO_PROGRAMADA],
                        freeDelivery: false,
                        percentageDelivery: null,
                        stock: false,
                        stockSize: 0,
                    },
                }

                const total = calculateTotalCashback(state)

                state = {
                    ...state,
                    ...total
                }
            }

            return {
                ...state,
            }
        }

        case SET_DELIVERY_SIZE: {
            state.rules = {
                ...state.rules,
                percentageDelivery: action?.payload
            }
            return {
                ...state,
            }
        }

        case SET_USE_COLOR: {
            return {
                ...state,
                useColor: !state?.useColor,
                color: (state?.useColor) ? '' : state?.color,
                opacity: (state?.useColor) ? 100 : state?.opacity,
            }
        }

        case SET_COLOR: {
            return {
                ...state,
                color: action?.payload
            }
        }

        case SET_OPACITY: {
            return {
                ...state,
                opacity: action?.payload
            }
        }

        default:
            return state
    }
}

const calculateTotalProducts = (state) => {
    const currentTotalPrizeRetiro = state.products.reduce((e, i) => {
        return (e + (i?.PrecioFinalRetiro * i?.CantidadMinima))
    }, 0)

    const currentTotalPrizeDelivery = state.products.reduce((e, i) => {
        return (e + (i?.PrecioFinalDelivery * i?.CantidadMinima))
    }, 0)

    const promotionTotalPrizeRetiro = state.products.reduce((e, i) => {
        return (e + (i?.PrecioPromocionRetiro * i?.CantidadMinima))
    }, 0)

    const promotionTotalPrizeDelivery = state.products.reduce((e, i) => {
        return (e + (i?.PrecioPromocionDelivery * i?.CantidadMinima))
    }, 0)

    return {
        currentTotalPrizeRetiro,
        currentTotalPrizeDelivery,
        promotionTotalPrizeRetiro,
        promotionTotalPrizeDelivery
    }
}

const calculateTotalCashback = (state) => {
    const totalPedidos = state.cashback.reduce((e, i) => {
        return (e + i?.CantidadPedidos)
    }, 0)

    const totalSubtotal = state.cashback.reduce((e, i) => {
        return (e + i?.SubtotalMinimo)
    }, 0)

    const totalPuntos = state.cashback.reduce((e, i) => {
        return (e + i?.CantidadPuntos)
    }, 0)

    return {
        totalPedidos,
        totalSubtotal,
        totalPuntos,
    }
}