export default {
  // ACTIONS

  LOGIN_WITH_TOKEN: 'LOGIN_WITH_TOKEN',
  AUTHENTICATE: "AUTHENTICATE",
  AUTHENTICATE_SUCCESS: "AUTHENTICATE_SUCCESS",
  LOGOUT: "LOGOUT",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",


  SECREPASSPHRASE: '__COMERCIO_ADMIN__',

  // CONST
  ID_COM_TIPO_DISE_LOGO: 1,
  ID_COM_TIPO_DISE_BANER: 2,
  ID_COM_TIPO_DISE_CARRUSEL: 3,
  ID_COM_TIPO_DISE_AVATAR: 4,


  ID_TIPO_URL_COMERCIO_ADMIN: 1,
  ID_TIPO_URL_COMERCIO_SHOP: 2,

  ID_PERFIL_ADMINISTRADOR: 2,
  ID_PERFIL_CAJERO: 5,

  ID_USUARIO_PERFIL_ESTADO_ACTIVO: 1,
  ID_USUARIO_PERFIL_ESTADO_INACTIVO: 2,

  SET_PAYMENT_DETAIL: "SET_PAYMENT_DETAIL"
}
