export const REGLA_CANAL = 10
export const REGLA_TIPO_ENTREGA = 20
export const REGLA_SOLO_USUARIOS_NUEVOS = 30
export const REGLA_CANTIDAD_CUPONES_POR_USUARIO = 40
export const REGLA_MONTO_MINIMO_SUBTOTAL = 50
export const REGLA_INCLUYE_PRODUCTOS = 60
export const REGLA_CANTIDAD_PRODUCTOS_AFECTOS = 61
export const REGLA_EXCLUYE_PRODUCTOS = 70
export const REGLA_INCLUYE_CATEGORIAS = 80
export const REGLA_CANTIDAD_CATEGORIAS_AFECTOS = 81
export const REGLA_EXCLUYE_CATEGORIAS = 90
export const REGLA_INCLUYE_MEDIOS_PAGO = 100
export const REGLA_DIAS_SEMANA = 110
export const REGLA_BLOQUE_HORARIO_COMPRA = 120
export const REGLA_FECHA_ESPECIFICA_COMPRA = 130
export const REGLA_FECHA_ESPECIFICA_ENTREGA = 140
export const REGLA_BLOQUE_HORARIO_ENTREGA = 150