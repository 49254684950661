import styled, {css} from "styled-components";
import {grey} from "@material-ui/core/colors";


export const InsightsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  // border: 1px solid red;
  padding: 10px;
`;

export const InsightsBody = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  // border: 1px solid crimson;
`;

export const InsightsHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  // border: 1px solid magenta;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 3px;
  background-color: white;
`;


export const InsightsSearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;


export const InsightsListContainer = styled.div`
  display: flex;

  ${({direction}) => {
      
      if(direction === "column"){
          return css`
            flex-direction: column;
          `;
      }else{
        return css`
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
        `;
      }
  }};
  
  width: 100%;
  // border: 2px solid green;
  padding: 10px;
  
  height: 58vh;
  overflow-x: scroll;
`;


export const InsightsNoData =  styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  
  & img{
    max-width: 300px;
  }
`;


export const InsightPaginatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;


export const InsightLoading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  & .image-container {
    
    border-radius: 20px;
    overflow: hidden;
    & img{
      max-width: 320px;
    }
  }
  
  & .title{
    font-size: 18px;
    color: ${grey[500]};
  }
  
`;