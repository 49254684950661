import React, { useEffect, useState, Fragment } from 'react'
import axios from "axios";
import { LAMBDA_COMMON, LAMBDA_POS } from "../../../config"
import { useSelector } from 'react-redux'
import {
  Avatar,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Container,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
  Divider,
  IconButton,
  Button,
  Paper,
  useMediaQuery,
} from "@material-ui/core";
import currencyFormatter from 'currency-formatter';
import currencyFormatterOptions from '../../../config/currencyFormatterOptions.json'
import { ArrowBack, Stars, WhatsApp } from "@material-ui/icons";
import { AvatarBagde, AvatarContainer, NombreCliente } from "../CardCliente/CardCliente.style";
import CardCliente, { LightTooltip } from "../CardCliente/CardCliente";
import { ContainerGraf, InfoContainer } from "./PopupShoppingBehaviour.style";
import moment from "moment-timezone";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import TableContainer from '@material-ui/core/TableContainer';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import LoadingClient from '../../../common/components/Loading'
import { Chart } from "react-google-charts";

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Row(props) {
  const theme = useTheme()
  const MediaQuery = useMediaQuery(theme.breakpoints.up('md'))
  const { row } = props;
  const [open, setOpen] = useState(false);
  const [dataDetail, setDataDetail] = useState(null);
  const [showModalDetail, setShowModalDetail] = useState(null);
  const classes = useRowStyles();
  const IdSucursal = useSelector<any, any>(state => state?.auth?.payload?.usuarioPerfil?.sucursal?.IdSucursal)
  const Disenos = useSelector<any, any>(state => state?.auth?.payload?.usuarioPerfil?.sucursal?.comercio?.disenos)
  const Moneda = useSelector<any, any>(state => state?.auth.payload.usuarioPerfil.sucursal.comercio.nacionalidad.Moneda)
  const Logo = Disenos[2].Recurso

  const getDetailsOrder = async (idPedido) => {
    if (idPedido) {
      const response = await axios.get(`${LAMBDA_POS}/getOrder/${IdSucursal}/${idPedido}`)
      if (response?.data?.order) {
        const productos = []

        response.data.order.productos.map((e) => {
          productos.push({
            imagen: e?.Imagen,
            nombre: e?.Nombre,
            cantidad: e?.pedido_detalle?.Cantidad,
            monto: e?.pedido_detalle?.Monto,
          })
        })

        setDataDetail({
          codigo: response?.data?.order?.Codigo,
          correlativo: response?.data?.order?.Corelativo,
          tipoPedido: response?.data?.order?.tipoPedido?.Nombre,
          tipoEntrega: response?.data?.order?.entrega.tipoEntrega.Tipo,
          tipoFechaEntrega: response?.data?.order?.entrega.tipoFechaEntrega.Tipo,
          direccion: response?.data?.order?.direccionCliente.Direccion,
          fechaEntrega: moment(response?.data?.order?.entrega.FechaEstimadaEntregaMax).format("DD/MM/YYYY HH:mm:SS"),
          subtotal: response?.data?.order?.TotalPago,
          canjePuntos: response?.data?.order?.DsctoPuntos,
          propina: response?.data?.order?.Propina,
          despacho: response?.data?.order?.GastosEnvio,
          total: response?.data?.order?.TotalOC,
          productos,
        })
        setShowModalDetail(true)
      } else {
        setDataDetail(null)
      }
    }
  }

  const closeModalDetail = () => {
    setShowModalDetail(false)
    setDataDetail(null)
  }

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}({row.detalle.length})
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" align="center">{row.fecha}</TableCell>
        <TableCell align="right">{row.subTotal}</TableCell>
        <TableCell align="right">{row.propina}</TableCell>
        <TableCell align="right">{row.delivery}</TableCell>
        <TableCell align="right">{row.total}</TableCell>
      </TableRow>
      <TableRow style={{ backgroundColor: '#d0ced4' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 40, paddingRight: 40 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1} style={{ backgroundColor: '#fff', marginTop: 30, marginBottom: 30 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>H. Entrega</TableCell>
                    <TableCell>Fecha Compra</TableCell>
                    <TableCell>Medio Pago</TableCell>
                    <TableCell>Pedido</TableCell>
                    <TableCell>Estado</TableCell>
                    <TableCell>Dirección</TableCell>
                    <TableCell align="right">SubTotal</TableCell>
                    <TableCell align="right">Propina</TableCell>
                    <TableCell align="right">Delivery</TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.detalle.map((historyRow) => (
                    <TableRow key={historyRow.pedido} style={{ cursor: 'pointer' }} onClick={() => getDetailsOrder(historyRow?.idPedido)}>
                      <TableCell>{historyRow.horaEntrega}</TableCell>
                      <TableCell>{historyRow.fechaCompra}</TableCell>
                      <TableCell>{historyRow.medioPago}</TableCell>
                      <TableCell component="th" scope="row">{historyRow.pedido}</TableCell>
                      <TableCell>{historyRow.estado}</TableCell>
                      <TableCell>{historyRow.direccion}</TableCell>
                      <TableCell align="right">{historyRow.subTotal}</TableCell>
                      <TableCell align="right">{historyRow.propina}</TableCell>
                      <TableCell align="right">{historyRow.delivery}</TableCell>
                      <TableCell align="right">{historyRow.total}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <Dialog
        maxWidth='md'
        fullWidth={true}
        fullScreen={(MediaQuery) ? false : true}
        open={showModalDetail}
        // open={true}
        onClose={closeModalDetail}
      >
        <Container
        // style={(MediaQuery) ? { textAlign: 'center' } : { display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center', height: '80vh' }}
        >
          <Grid container spacing={3} style={{ marginTop: 20, marginBottom: 30 }}>
            <Grid item xs={8} style={{ textAlign: 'center' }}>
              <Typography variant="h5">{dataDetail?.tipoEntrega}</Typography>
              <Typography variant="h4"><b>{dataDetail?.codigo}-{dataDetail?.correlativo}</b></Typography>
            </Grid>
            <Grid item xs={4}>
              <img src={Logo} alt="" style={{ width: (MediaQuery) ? '50%' : '70%' }} />
            </Grid>

            {
              (MediaQuery) ?
                <Grid item xs={12}>
                  <Grid container spacing={3} >
                    <Grid item md={4} sm={6} xs={6} style={{ textAlign: 'center' }}>
                      <Typography variant='h6'><b>Tipo Pedido:</b></Typography>
                    </Grid>
                    <Grid item md={4} sm={6} xs={6} style={{ textAlign: 'center' }}>
                      <Typography variant='h6'><b>Fecha Entrega:</b></Typography>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12} style={{ textAlign: 'center' }}>
                      <Typography variant='h6'><b>Dirección:</b></Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} >
                    <Grid item md={4} sm={6} xs={6} style={{ textAlign: 'center' }}>
                      <Typography variant='h6'>{dataDetail?.tipoPedido}</Typography>
                    </Grid>
                    <Grid item md={4} sm={6} xs={6} style={{ textAlign: 'center' }}>
                      <Typography variant='h6'>{`${dataDetail?.fechaEntrega} (${dataDetail?.tipoPedido})`}</Typography>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12} style={{ textAlign: 'center' }}>
                      <Typography variant='h6'>{dataDetail?.direccion}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                :
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={4} sm={6} xs={6} style={{ textAlign: 'center' }}>
                      <Typography variant='h6'><b>Tipo Pedido:</b></Typography>
                    </Grid>
                    <Grid item md={4} sm={6} xs={6} style={{ textAlign: 'center' }}>
                      <Typography variant='h6'>{dataDetail?.tipoPedido}</Typography>
                    </Grid>
                    <Grid item md={4} sm={6} xs={6} style={{ textAlign: 'center' }}>
                      <Typography variant='h6'><b>Fecha Entrega:</b></Typography>
                    </Grid>
                    <Grid item md={4} sm={6} xs={6} style={{ textAlign: 'center' }}>
                      <Typography variant='h6'>{`${dataDetail?.fechaEntrega} (${dataDetail?.tipoPedido})`}</Typography>
                    </Grid>
                    <Grid item md={4} sm={6} xs={6} style={{ textAlign: 'center' }}>
                      <Typography variant='h6'><b>Dirección:</b></Typography>
                    </Grid>
                    <Grid item md={4} sm={6} xs={6} style={{ textAlign: 'center' }}>
                      <Typography variant='h6'>{dataDetail?.direccion}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
            }

            <Grid item xs={12}>
              {
                (dataDetail?.productos !== undefined && dataDetail?.productos !== null && dataDetail?.productos.length > 0) && (
                  <>
                    <Divider style={{ marginTop: 15, marginBottom: 15 }} />
                    <Grid container spacing={3} >
                      <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography variant='h6'><b>Productos</b></Typography>
                      </Grid>
                    </Grid>
                    {
                      (MediaQuery) ?
                        <>
                          <Grid container spacing={3} >
                            <Grid item xs={4} style={{ textAlign: 'center' }}>
                              <Typography variant='h6'><b>Nombre</b></Typography>
                            </Grid>
                            <Grid item xs={4} style={{ textAlign: 'center' }}>
                              <Typography variant='h6'><b>Cantidad</b></Typography>
                            </Grid>
                            <Grid item xs={4} style={{ textAlign: 'center' }}>
                              <Typography variant='h6'><b>Monto</b></Typography>
                            </Grid>
                          </Grid>
                          {
                            dataDetail?.productos.map((e, i) => {
                              return (
                                <Grid container spacing={3} style={{ marginTop: 10 }}>
                                  <Grid item md={4} sm={12} xs={12} style={{ textAlign: 'center' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                      <img
                                        style={{
                                          width: 50
                                          , height: 50
                                          , borderRadius: 100
                                          , objectFit: "cover"
                                        }}
                                        src={e?.imagen}
                                      />
                                      <Typography variant='body1'>{e?.nombre}</Typography>
                                    </div>
                                  </Grid>
                                  <Grid item md={4} sm={6} xs={6} style={{ textAlign: 'center' }}>
                                    <Typography variant='h6'>{e?.cantidad}</Typography>
                                  </Grid>
                                  <Grid item md={4} sm={6} xs={6} style={{ textAlign: 'center' }}>
                                    <Typography variant='h6'>{currencyFormatter.format(e?.monto, { ...currencyFormatterOptions[Moneda] })}</Typography>
                                  </Grid>
                                </Grid>
                              )
                            })
                          }
                        </>
                        :
                        <>
                          {
                            dataDetail?.productos.map((e, i) => {
                              return (
                                <Grid container spacing={3} style={{ marginTop: 10 }}>
                                  <Grid item md={4} sm={12} xs={12} style={{ textAlign: 'center' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                      <img
                                        style={{
                                          width: 50
                                          , height: 50
                                          , borderRadius: 100
                                          , objectFit: "cover"
                                        }}
                                        src={e?.imagen}
                                      />
                                      <Typography variant='body1'>{e?.nombre}</Typography>
                                    </div>
                                  </Grid>
                                  <Grid item md={4} sm={6} xs={6} style={{ textAlign: 'center' }}>
                                    <Typography variant='h6'><b>Cantidad</b></Typography>
                                  </Grid>
                                  <Grid item md={4} sm={6} xs={6} style={{ textAlign: 'center' }}>
                                    <Typography variant='h6'>{e?.cantidad}</Typography>
                                  </Grid>
                                  <Grid item md={4} sm={6} xs={6} style={{ textAlign: 'center' }}>
                                    <Typography variant='h6'><b>Monto</b></Typography>
                                  </Grid>
                                  <Grid item md={4} sm={6} xs={6} style={{ textAlign: 'center' }}>
                                    <Typography variant='h6'>{currencyFormatter.format(e?.monto, { ...currencyFormatterOptions[Moneda] })}</Typography>
                                  </Grid>
                                </Grid>
                              )
                            })
                          }
                        </>
                    }

                    <Divider style={{ marginTop: 15, marginBottom: 15 }} />
                    <Grid container spacing={3} >
                      {
                        (MediaQuery) && (
                          <Grid item md={4} sm={12} xs={12} />
                        )
                      }
                      <Grid item md={4} sm={6} xs={6} style={{ textAlign: 'end' }}>
                        <Typography variant='h6'>Subtotal:</Typography>
                      </Grid>
                      <Grid item md={4} sm={6} xs={6} style={{ textAlign: 'center' }}>
                        <Typography variant='h6'>{currencyFormatter.format(dataDetail?.subtotal, { ...currencyFormatterOptions[Moneda] })}</Typography>
                      </Grid>
                    </Grid>
                    {
                      (dataDetail?.canjePuntos !== undefined && dataDetail?.canjePuntos !== null && dataDetail?.canjePuntos > 0) && (
                        <Grid container spacing={3} >
                          {
                            (MediaQuery) && (
                              <Grid item md={4} sm={12} xs={12} />
                            )
                          }
                          <Grid item md={4} sm={6} xs={6} style={{ textAlign: 'end' }}>
                            <Typography variant='h6'>Canje Puntos:</Typography>
                          </Grid>
                          <Grid item md={4} sm={6} xs={6} style={{ textAlign: 'center' }}>
                            <Typography variant='h6'>{currencyFormatter.format(dataDetail?.canjePuntos, { ...currencyFormatterOptions[Moneda] })}</Typography>
                          </Grid>
                        </Grid>
                      )
                    }
                    <Grid container spacing={3} >
                      {
                        (MediaQuery) && (
                          <Grid item md={4} sm={12} xs={12} />
                        )
                      }
                      <Grid item md={4} sm={6} xs={6} style={{ textAlign: 'end' }}>
                        <Typography variant='h6'>Propina:</Typography>
                      </Grid>
                      <Grid item md={4} sm={6} xs={6} style={{ textAlign: 'center' }}>
                        <Typography variant='h6'>{currencyFormatter.format(dataDetail?.propina, { ...currencyFormatterOptions[Moneda] })}</Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} >
                      {
                        (MediaQuery) && (
                          <Grid item md={4} sm={12} xs={12} />
                        )
                      }
                      <Grid item md={4} sm={6} xs={6} style={{ textAlign: 'end' }}>
                        <Typography variant='h6'>Despacho:</Typography>
                      </Grid>
                      <Grid item md={4} sm={6} xs={6} style={{ textAlign: 'center' }}>
                        <Typography variant='h6'>{currencyFormatter.format(dataDetail?.despacho, { ...currencyFormatterOptions[Moneda] })}</Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} >
                      {
                        (MediaQuery) && (
                          <Grid item md={6} sm={12} xs={12} />
                        )
                      }
                      <Grid item md={6} sm={12} xs={12} >
                        <Divider />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} >
                      {
                        (MediaQuery) && (
                          <Grid item md={4} sm={12} xs={12} />
                        )
                      }
                      <Grid item md={4} sm={6} xs={6} style={{ textAlign: 'end' }}>
                        <Typography variant='h6'><b>Total:</b></Typography>
                      </Grid>
                      <Grid item md={4} sm={6} xs={6} style={{ textAlign: 'center' }}>
                        <Typography variant='h6'><b>{currencyFormatter.format(dataDetail?.total, { ...currencyFormatterOptions[Moneda] })}</b></Typography>
                      </Grid>
                    </Grid>

                  </>
                )
              }
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="primary"
                size='large'
                fullWidth
                onClick={closeModalDetail}
              >
                Volver
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </>
  );
}

const PopupShoppingBehaviour = ({ onClose, open, selectedCliente, isAddingPoints, idComercio }) => {

  const auth = useSelector<any, any>(state => state.auth);

  const [data, setData] = useState([])
  const [dataC, setDataC] = useState([])
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const Moneda = useSelector<any, any>(state => state?.auth.payload.usuarioPerfil.sucursal.comercio.nacionalidad.Moneda)

  const formatCurrency = (value, hideSymbol = false) => {
    if (hideSymbol) {
      return currencyFormatter.format(value, { ...currencyFormatterOptions[Moneda], symbol: "" })
    }
    return currencyFormatter.format(value, { ...currencyFormatterOptions[Moneda] })
  }

  // const mtdYtdFormat = `MTD: (` + selectedCliente?.CantidadPedidosMTD + ') ' + formatCurrency(selectedCliente?.MontoPedidosMTD) +
  //   ' - YTD: (' + selectedCliente?.CantidadPedidosYTD + ') ' + formatCurrency(selectedCliente?.MontoPedidosMYD);

  const options = {
    title: 'Gráfico de las últimas 14 compras',
    vAxis: {
      title: "Monto pedidos",
      format: '$#,###',
    },
    hAxis: {
      title: 'Fechas pedido',
    },
    seriesType: 'bars',
    series: {
      1: {
        type: 'line'
      },
      2: {
        type: 'line'
      },
      3: {
        type: 'line'
      },
    }
  };

  useEffect(() => {
    if (open && selectedCliente) {
      setLoading(true);
      setError(false);
      setData([])
      axios.get(`${LAMBDA_COMMON}/getBehaviourDW/${selectedCliente.IdCliente}/${idComercio}`, {
        headers: { 'content-type': 'application/json' },
      })
        .then((response) => {
          const responseData = response.data.behaviour.sort((a, b) => b.FechaCompraClienteOrden - a.FechaCompraClienteOrden);
          const distince = Array.from(new Set(responseData.map(row => row.FechaEntrega)))
          let data = [];
          let dataC = [];

          for (let i = 0; i < distince.length; i++) {

            const dataFiltered = responseData.filter(row => row.FechaEntrega === distince[i])

            let PropinaDelivery = dataFiltered.reduce((accumulator, current) => accumulator + Number(current["PropinaDelivery"]), 0);
            let SubtotalProductos = dataFiltered.reduce((accumulator, current) => accumulator + Number(current["SubtotalProductos"]), 0);;
            let DeliveryCharge = dataFiltered.reduce((accumulator, current) => accumulator + Number(current["PagoEnvioCliente"]), 0);;
            let TotalBoleta = dataFiltered.reduce((accumulator, current) => accumulator + Number(current["TotalBoleta"]), 0);;

            data.push({
              fecha: distince[i],
              subTotal: formatCurrency(SubtotalProductos),
              propina: formatCurrency(PropinaDelivery),
              delivery: formatCurrency(DeliveryCharge),
              total: formatCurrency(TotalBoleta),
              detalle: dataFiltered.map(row => ({
                idPedido: row.IdPedido,
                horaEntrega: row.HoraEntrega,
                fechaCompra: row.FechaCompraCliente,
                medioPago: row.MedioPagoNombre,
                pedido: row.Codigo + "-" + row.Correlativo,
                estado: row.EstadoPedido,
                direccion: row.DireccionCliente,
                subTotal: formatCurrency(row.SubtotalProductos),
                propina: formatCurrency(row.PropinaDelivery),
                delivery: formatCurrency(row.PagoEnvioCliente),
                total: formatCurrency(row.TotalBoleta),
              }))
            })

            const getDM = (dmy) => {
              if (!dmy) {
                return '';
              }
              const sp = dmy.split('/');
              return sp[0] + '/' + sp[1];
            }
            for (let a = 0; a < dataFiltered.length; a++) {
              dataC.push([
                getDM(distince[i]),
                dataFiltered[a].TotalBoleta,
                selectedCliente.CompraMaxima,
                selectedCliente.CompraPromedio,
                selectedCliente.CompraMinima
              ])
            }
          }

          dataC = dataC.slice(0, 14).reverse()

          dataC.unshift([
            "Fecha",
            "Monto",
            "Máximo histórico: " + formatCurrency(selectedCliente.CompraMaxima),
            "Promedio histórico: " + formatCurrency(selectedCliente.CompraPromedio),
            "Mínimo histórico: " + formatCurrency(selectedCliente.CompraMinima)
          ])

          setData(data);
          setDataC(dataC);
        }).catch((err) => {
          console.error(err);
          setError(true);
        }).finally(() => {
          setLoading(false);
        })
    }
  }, [open])


  return (<Dialog open={open} onClose={onClose} fullScreen>
    <DialogTitle> {!isAddingPoints && <IconButton onClick={onClose}><ArrowBack /></IconButton>}Comportamiento de Compra</DialogTitle>

    {loading && <DialogContent>
      <LoadingClient title="Cargando datos..." />
    </DialogContent>
    }

    {!loading && <DialogContent>
      <ContainerGraf  >

        {
          selectedCliente &&
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", paddingLeft: 40 }}>
              <AvatarContainer>
                <Avatar src={selectedCliente.Imagen} style={{ width: 150, height: 150 }} />
                <LightTooltip title={selectedCliente.badgeConf.info}>
                  <AvatarBagde background={selectedCliente.badgeConf.background} color={selectedCliente.badgeConf.color}><Stars className={"star-badge"} /> {selectedCliente.badgeConf.text}</AvatarBagde>
                </LightTooltip>
              </AvatarContainer>
              <NombreCliente style={{ marginTop: 10 }}>{selectedCliente.nombreCliente}</NombreCliente>
            </div>
            <div>
              <InfoContainer style={{ alignItems: "flex-start", minWidth: "300px", paddingLeft: 40 }}>

                <p><strong>Primer pedido:</strong></p>
                <p>{moment(selectedCliente.FechaPrimerPedido).format("DD/MM/YYYY HH:mm:SS")}</p>

                <p style={{ marginTop: 20 }}><strong>Último pedido:</strong></p>
                <p>{moment(selectedCliente.FechaUltimoPedido).format("DD/MM/YYYY HH:mm:SS")}</p>

                <p style={{ marginTop: 20 }}><strong>Cantidad de Pedidos:</strong></p>
                <p>{`${selectedCliente.CantidadPedidosHistorico} (${selectedCliente.CantidadPedidosPendientePago} Pendientes, ${selectedCliente.CantidadReembolsosSolicitados} Reembolsos)`}</p>

                <p style={{ marginTop: 20 }}><strong>Pasarela de pago preferida:</strong></p>
                <p>{selectedCliente.PasarelaPagoPreferencia}</p>
              </InfoContainer>
            </div>

          </div>
        }

        {
          selectedCliente &&
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", }}>

            <InfoContainer style={{ alignItems: "flex-start", width: "100%", marginTop: 30 }}>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'center', cursor: 'help' }}>
                <LightTooltip title={'(Month to date): la cantidad de pedidos realizados por el cliente desde el primer día del mes hasta el día actual.'}>
                  <Typography style={{ fontWeight: 700 }}>
                    {`MTD: (${selectedCliente?.CantidadPedidosMTD}) ${formatCurrency(selectedCliente?.MontoPedidosMTD)} `}
                  </Typography>
                </LightTooltip>
                <Typography style={{ marginLeft: 5, marginRight: 5, fontWeight: 700 }}>-</Typography>
                <LightTooltip title={'(Year to Date): la cantidad de pedidos realizados por el cliente desde el primer día del año hasta el día actual.'}>
                  <Typography style={{ fontWeight: 700 }}>
                    {` YTD: (${selectedCliente?.CantidadPedidosYTD}) ${formatCurrency(selectedCliente?.MontoPedidosYTD)} `}
                  </Typography>
                </LightTooltip>

              </div>

              <div style={{ border: "0px solid #cbcbcb", width: '60vw' }}>
                <Chart
                  chartType="ComboChart"
                  width="100%"
                  height="400px"
                  data={dataC}
                  options={options}
                />
              </div>
            </InfoContainer>
          </div>
        }
      </ContainerGraf>
      <div style={{ paddingLeft: 40, paddingRight: 40 }}>
        <TableContainer component={Paper} style={{ marginTop: 60 }}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell align="center">Dia</TableCell>
                <TableCell align="center">SubTotal</TableCell>
                <TableCell align="center">Propina</TableCell>
                <TableCell align="center">Delivery</TableCell>
                <TableCell align="center">Neto</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, i) => (
                <Row key={i} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </DialogContent>
    }
  </Dialog>
  )
}

export default PopupShoppingBehaviour;