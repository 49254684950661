import styled from "styled-components";
import { grey, yellow } from "@material-ui/core/colors";

// CARD de Cliente
export const CardClienteContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  width: 100%;
  border: 1px solid ${grey[400]};
  border-radius: 10px;
  background-color: white;
  padding: 20px;
  justify-content: space-around;

  @media screen and (max-width: 900px){
    flex-direction: column;
    align-items: center;
  }
`

export const FirstSectionContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 900px){
    flex-direction: column;
    align-items: center;
  }
`;

export const SecondSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 900px){
    flex-direction: column;
    align-items: center;
  }
`;

export const AvatarContainer = styled.div`
  position: relative;
  // border: brown 2px solid;
  
  .MuiAvatar-root {
    width: 110px;
    height: 110px;


    @media screen and (max-width: 900px){
      width: 80px;
      height: 80px;
      margin-bottom: 20px;
    }
  }
`;


export const AvatarBagde = styled.div`
  background: ${({ background }) => background || "white"};
  color: ${({ color }) => color || "white"};
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 10px;
  position: absolute;
  bottom: 0px;
  left: -10px;
  padding: 5px 10px;
  font-size: 12px;
  
  & .star-badge{
    font-size: 16px;
    margin-right: 5px;
  }
  
  &:hover {
    cursor: help;
  }

  @media screen and (max-width: 900px){
    bottom: 10px;
  }
`;

export const MainInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  justify-content: flex-start;
  // border: 1px solid brown;
`;

export const NombreCliente = styled.p`
  font-size: 22px;
  text-transform: capitalize;
  margin-bottom: 2px;
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // position: relative;

  @media screen and (max-width: 900px){
    text-align: center;
  }
`;

export const ReputationContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CalificacionArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 14px;
  margin-top: 0;
  
  & .star{
    color: ${yellow[800]};
    margin-right: 5px;
  }
  
  & p{
    text-transform: lowercase;
    margin: 0;
  }
`;

export const HorizontalDiv = styled.div`
  border-left: 1px solid grey;
  margin: 5px;
`;

export const AntiguedadArea = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  font-size: 14px;
  margin-top: 0;
`;

export const AnalisisVentaContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
`;

export const TipoEntregaArea = styled.div`
  margin-top: 10px;
`;

export const AnalisisVentaIndicadores = styled.div`
  
    & p{
      margin: 0;
      font-size: 14px;
    }
`;



export const InactividadContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  // border: 1px solid green;
  justify-content: center;
  align-items: center;
  cursor: help;
  min-width: 200px;

  @media screen and (max-width: 900px){
    margin-bottom: 10px;
    width: 100%;
  }
`;

export const ChartInactividadArea = styled.div`
  width: 100px;
  height: 60px;
`;

export const LabelInactividad = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
`;

export const InactividadDetalle = styled.div`
    
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
    & p{
      margin-top: 0;
      margin-bottom: 0;
      font-size: 10px;
    }
`;

export const LastKpisContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  min-width: 300px;
  // border: 1px solid red;

  @media screen and (max-width: 900px){
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;


export const PointsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

export const PointsValue = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  
    & p{
      font-size: 18px;
      margin-top: 0;
      margin-bottom: 0;
    }
  
  & .title{
    font-size: 14px;
  }
`;

export const PointsIconArea = styled.div`
  //   margin-left: 5px;
`;

export const AnalisisHorarioContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: help;
`;

export const DayContainer = styled.div`
  padding: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DayValue = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${({ color }) => color || grey[200]};
`;

export const DayName = styled.div`
  font-size: 10px;
  margin-top: 4px;
`;

