import {
    SET_INIT_PRODUCT_SYNC,
    SET_RESET_PRODUCT_SYNC,
    SET_MATCH_PRODUCT,
    SET_PRODUCT_ACTION,
    ACTION_MATCH,
    ACTION_CREATE,
    ACTION_IGNORE,
    SET_MATCH_MODIFIER,
    SET_MODIFIER_ACTION,
    SET_OVERWRITE_PRICE,
} from "./types"

const INIT_STATE = {
    ProductAgil: [],
    ProductIntegrator: [],
    ModifierAgil: [],
    ModifierIntegrator: [],
}

export default (state: any = INIT_STATE, action: any) => {
    switch (action?.type) {
        case SET_INIT_PRODUCT_SYNC: {
            // PRODUCTS AGIL
            state.ProductAgil = [
                ...action?.payload?.Product?.matcheados,
                ...action?.payload?.Product?.noMacheados
            ]

            // PRODUCTS INTEGRATOR
            const producIntegrator = []

            action.payload.Product.allProductIntegrator.map((e: any) => {
                const productFound = action.payload.Product.matcheados.find((x: any) => x?.ProductIntegrator?.id === e?.id)

                producIntegrator.push({
                    action: (productFound) ? ACTION_MATCH : ACTION_IGNORE,
                    ProductIntegrator: {
                        ...e,
                        originalPrice: e?.price
                    },
                    ProductAgil: (productFound) ? { ...productFound?.ProductAgil } : null,
                    isConfirmed: (productFound) ? true : false,
                })
            })

            state.ProductIntegrator = producIntegrator


            // MODIFIER AGIL
            state.ModifierAgil = [
                ...action?.payload?.Modify?.matcheados,
                ...action?.payload?.Modify?.noMacheados
            ]

            // MODIFIER INTEGRATOR
            const modifierIntegrator = []

            action.payload.Modify.allModifyIntegrator.map((e: any) => {
                const modifierFound = action.payload.Modify.matcheados.find((x: any) => x?.ProductIntegrator?.id === e?.id)

                modifierIntegrator.push({
                    action: (modifierFound) ? ACTION_MATCH : ACTION_IGNORE,
                    ProductIntegrator: {
                        ...e
                    },
                    ProductAgil: (modifierFound) ? { ...modifierFound?.ProductAgil } : null,
                    isConfirmed: (modifierFound) ? true : false
                })
            })

            state.ModifierIntegrator = modifierIntegrator

            return {
                ...state,
            }
        }

        case SET_RESET_PRODUCT_SYNC: {
            state.ProductAgil = INIT_STATE?.ProductAgil
            state.ProductIntegrator = INIT_STATE?.ProductIntegrator
            state.ModifierAgil = INIT_STATE?.ModifierAgil
            state.ModifierIntegrator = INIT_STATE?.ModifierIntegrator
            return {
                ...state
            }
        }

        case SET_MATCH_PRODUCT: {
            if (state.ProductAgil[action?.payload?.positionAgil]) {
                const agilFound = state.ProductAgil.map((e: any, i: number) => (e?.ProductIntegrator?.id === state.ProductIntegrator[action?.payload?.positionIntegrator]?.ProductIntegrator?.id) ? i : 0).filter((e: any) => e > 0)

                agilFound.map((e: any) => {
                    state.ProductAgil[e] = {
                        ...state.ProductAgil[e],
                        ProductIntegrator: null,
                        action: ACTION_MATCH
                    }
                })

                state.ProductAgil[action?.payload?.positionAgil] = {
                    ...state.ProductAgil[action?.payload?.positionAgil],
                    ProductIntegrator: state.ProductIntegrator[action?.payload?.positionIntegrator]?.ProductIntegrator,
                    action: ACTION_MATCH
                }
            }

            if (state.ProductIntegrator[action?.payload?.positionIntegrator]) {
                const integratorFound = state.ProductIntegrator.map((e: any, i: number) => (e?.ProductAgil?.id === state.ProductAgil[action?.payload?.positionAgil]?.ProductAgil?.id) ? i : 0).filter((e: any) => e > 0)

                integratorFound.map((e: any) => {
                    state.ProductIntegrator[e] = {
                        ...state.ProductIntegrator[e],
                        ProductAgil: null,
                        action: ACTION_IGNORE
                    }
                })

                state.ProductIntegrator[action?.payload?.positionIntegrator] = {
                    ...state.ProductIntegrator[action?.payload?.positionIntegrator],
                    ProductAgil: state.ProductAgil[action?.payload?.positionAgil]?.ProductAgil,
                    action: ACTION_MATCH
                }
            }

            return {
                ...state
            }
        }

        case SET_PRODUCT_ACTION: {
            if (action?.payload?.type === 'AGIL') {
                if (state.ProductAgil[action?.payload?.position]) {
                    state.ProductAgil[action?.payload?.position] = {
                        ...state.ProductAgil[action?.payload?.position],
                        action: action?.payload?.action
                    }

                    if (state?.ProductAgil[action?.payload?.position]?.ProductIntegrator) {
                        const position = state.ProductIntegrator.findIndex((x: any) => x?.ProductIntegrator?.id === state?.ProductAgil[action?.payload?.position]?.ProductIntegrator?.id)
                        state.ProductIntegrator[position] = {
                            ...state.ProductIntegrator[position],
                            action: action?.payload?.action
                        }
                    }
                }
            } else {
                if (state.ProductIntegrator[action?.payload?.position]) {
                    state.ProductIntegrator[action?.payload?.position] = {
                        ...state.ProductIntegrator[action?.payload?.position],
                        action: action?.payload?.action
                    }

                    if (state?.ProductIntegrator[action?.payload?.position]?.ProductAgil) {
                        const position = state.ProductAgil.findIndex((x: any) => x?.ProductAgil?.id === state?.ProductIntegrator[action?.payload?.position]?.ProductAgil?.id)
                        state.ProductAgil[position] = {
                            ...state.ProductAgil[position],
                            action: action?.payload?.action
                        }
                    }
                }
            }

            return {
                ...state
            }
        }

        case SET_MATCH_MODIFIER: {
            if (state.ModifierAgil[action?.payload?.positionAgil]) {
                const agilFound = state.ModifierAgil.map((e: any, i: number) => (e?.ProductIntegrator?.id === state.ModifierIntegrator[action?.payload?.positionIntegrator]?.ProductIntegrator?.id) ? i : 0).filter((e: any) => e > 0)

                agilFound.map((e: any) => {
                    state.ModifierAgil[e] = {
                        ...state.ModifierAgil[e],
                        ProductIntegrator: null,
                        action: ACTION_MATCH
                    }
                })

                state.ModifierAgil[action?.payload?.positionAgil] = {
                    ...state.ModifierAgil[action?.payload?.positionAgil],
                    ProductIntegrator: state.ModifierIntegrator[action?.payload?.positionIntegrator]?.ProductIntegrator,
                    action: ACTION_MATCH
                }
            }

            if (state.ModifierIntegrator[action?.payload?.positionIntegrator]) {
                const integratorFound = state.ModifierIntegrator.map((e: any, i: number) => (e?.ProductIntegrator?.id === state.ModifierAgil[action?.payload?.positionIntegrator]?.ProductIntegrator?.id) ? i : 0).filter((e: any) => e > 0)

                integratorFound.map((e: any) => {
                    state.ModifierIntegrator[e] = {
                        ...state.ModifierIntegrator[e],
                        ProductAgil: null,
                        action: ACTION_IGNORE
                    }
                })

                state.ModifierIntegrator[action?.payload?.positionIntegrator] = {
                    ...state.ModifierIntegrator[action?.payload?.positionIntegrator],
                    ProductAgil: state.ModifierAgil[action?.payload?.positionAgil]?.ProductAgil,
                    action: ACTION_MATCH
                }
            }

            return {
                ...state
            }
        }

        case SET_MODIFIER_ACTION: {
            if (action?.payload?.type === 'AGIL') {
                if (state.ModifierAgil[action?.payload?.position]) {
                    state.ModifierAgil[action?.payload?.position] = {
                        ...state.ModifierAgil[action?.payload?.position],
                        action: action?.payload?.action
                    }

                    if (state?.ModifierAgil[action?.payload?.position]?.ProductIntegrator) {
                        const position = state.ModifierIntegrator.findIndex((x: any) => x?.ProductIntegrator?.id === state?.ModifierAgil[action?.payload?.position]?.ProductIntegrator?.id)
                        state.ModifierIntegrator[position] = {
                            ...state.ModifierIntegrator[position],
                            action: action?.payload?.action
                        }
                    }
                }
            } else {
                if (state.ModifierIntegrator[action?.payload?.position]) {
                    state.ModifierIntegrator[action?.payload?.position] = {
                        ...state.ModifierIntegrator[action?.payload?.position],
                        action: action?.payload?.action
                    }

                    if (state?.ModifierIntegrator[action?.payload?.position]?.ProductAgil) {
                        const position = state.ModifierAgil.findIndex((x: any) => x?.ProductAgil?.id === state?.ModifierIntegrator[action?.payload?.position]?.ProductAgil?.id)
                        state.ModifierAgil[position] = {
                            ...state.ModifierAgil[position],
                            action: action?.payload?.action
                        }
                    }
                }
            }

            return {
                ...state
            }
        }

        case SET_OVERWRITE_PRICE: {
            if (state.ProductIntegrator[action?.payload?.position]) {
                state.ProductIntegrator[action?.payload?.position] = {
                    ...state.ProductIntegrator[action?.payload?.position],
                }

                state.ProductIntegrator[action?.payload?.position].ProductIntegrator.price = action?.payload?.newPrice
            }

            return {
                ...state
            }
        }

        default:
            return state
    }
}