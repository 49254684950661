import moment from "moment"
import {
    SET_APLICA_MEDIOS_PAGO,
    SET_APLICA_PRODUCTOS_ESPECIFICOS,
    SET_CANAL,
    SET_AGREGAR_CANTIDAD_BLOQUES_COMPRA,
    SET_CANTIDAD_CUPONES,
    SET_CODIGO,
    SET_CUPONES_LIMITADOS,
    SET_CUPON_AGRUPADO,
    SET_DESCRIPCION,
    SET_DIAS_SEMANA,
    SET_EDIT_DATA_CUPON,
    SET_ELIMINAR_CUPON_AGRUPADO,
    SET_EXCLUSIVO_COMPRAS_ONLINE,
    SET_FECHA_FIN,
    SET_FECHA_INICIO,
    SET_APLICA_BLOQUES_HORARIOS_COMPRA,
    SET_HORA_FIN_COMPRA,
    SET_HORA_INICIO_COMPRA,
    SET_INCLUIR_REGLAS,
    SET_MEDIOS_PAGO,
    SET_MONTO_MINIMO,
    SET_NOMBRE,
    SET_INCLUYE_PRODUCTOS,
    SET_RESET_CUPON,
    SET_RESET_INCLUYE_PRODUCTOS,
    SET_TIPO,
    SET_TIPO_ENTREGA,
    SET_TIPO_VALOR,
    SET_VALIDO_TODAS_SUCURSALES,
    SET_VALOR_CUPON,
    SET_ELIMINAR_CANTIDAD_BLOQUES_COMPRA,
    SET_APLICA_FECHAS_COMPRA_ESPECIFICAS,
    SET_TIPO_FECHA_ESPECIFICA,
    SET_FECHA_COMPRA_ESPECIFICA,
    SET_AGREGAR_FECHA_COMPRA_ESPECIFICA,
    SET_ELIMINAR_FECHA_COMPRA_ESPECIFICA,
    SET_INCLUYE_CATEGORIAS,
    SET_LIMIT_EDIT_CUPON,
    SET_APLICA_FECHAS_HORAS_COMPRA_ESPECIFICAS,
    SET_ES_RESTRICTIVO,
    SET_APLICA_FECHAS_HORAS_ENTREGA_ESPECIFICAS,
    SET_APLICA_FECHAS_ENTREGA_ESPECIFICAS,
    SET_FECHA_ENTREGA_ESPECIFICA,
    SET_AGREGAR_FECHA_ENTREGA_ESPECIFICA,
    SET_ELIMINAR_FECHA_ENTREGA_ESPECIFICA,
    SET_APLICA_BLOQUES_HORARIOS_ENTREGA,
    SET_HORA_INICIO_ENTREGA,
    SET_HORA_FIN_ENTREGA,
    SET_AGREGAR_CANTIDAD_BLOQUES_ENTREGA,
    SET_ELIMINAR_CANTIDAD_BLOQUES_ENTREGA,
    SET_EXCLUIR_PRODUCTOS_ESPECIFICOS,
    SET_EXCLUYE_PRODUCTOS,
    SET_RESET_EXCLUYE_PRODUCTOS,
    SET_EXCLUYE_CATEGORIAS
} from "./types"
import {
    ID_TIPO_ENTREGA_DELIVERY,
    ID_TIPO_ENTREGA_RETIRO,
    TIPO_CANAL_APP,
    TIPO_CANAL_WEB,
    TIPO_FECHA_ESPECIFICA_COMPRA,
    TIPO_FECHA_ESPECIFICA_ENTREGA
} from "../../constants/common"
import {
    REGLA_BLOQUE_HORARIO_COMPRA,
    REGLA_BLOQUE_HORARIO_ENTREGA,
    REGLA_CANAL,
    REGLA_DIAS_SEMANA,
    REGLA_EXCLUYE_CATEGORIAS,
    REGLA_EXCLUYE_PRODUCTOS,
    REGLA_FECHA_ESPECIFICA_COMPRA,
    REGLA_FECHA_ESPECIFICA_ENTREGA,
    REGLA_INCLUYE_CATEGORIAS,
    REGLA_INCLUYE_MEDIOS_PAGO,
    REGLA_INCLUYE_PRODUCTOS,
    REGLA_MONTO_MINIMO_SUBTOTAL,
    REGLA_TIPO_ENTREGA
} from "../../constants/reglasCupon"

const INIT_STATE = {
    idCupon: null,
    codigoAgrupacion: null,
    tipoCupon: null,
    codigoCupon: null,
    nombreCupon: null,
    Agil: false,
    descripcionCupon: null,
    tipoValorCupon: null,
    tipoValorCuponTexto: null,
    valorCupon: 0,
    fechaInicioCupon: moment().format('YYYY-MM-DD'),
    fechaFinCupon: null,
    esRestrictivo: false,
    cuponesLimitados: false,
    cantidadCupones: 0,
    incluyeReglas: false,
    validoTodasSucursales: true,
    exclusivoComprasOnline: true,
    tipoEntrega: [],
    diasSemana: [],
    aplicaFechasHoraCompraEspecificas: false,
    aplicaFechasCompraEspecificas: false,
    cantidadFechasCompraEspecificas: 1,
    fechasCompraEspecificas: [],
    aplicaBloquesHorariosCompra: false,
    cantidadBloquesCompra: 1,
    bloqueHorarioCompra: [],
    aplicaFechasHoraEntregaEspecificas: false,
    aplicaFechasEntregaEspecificas: false,
    cantidadFechasEntregaEspecificas: 1,
    fechasEntregaEspecificas: [],
    aplicaBloquesHorariosEntrega: false,
    cantidadBloquesEntrega: 1,
    bloqueHorarioEntrega: [],
    canal: [],
    montoMinimo: 0,
    aplicaProductosEspecificos: false,
    excluyeProductosEspecificos: false,
    incluyeProductos: [],
    excluyeProductos: [],
    aplicaMediosPago: false,
    mediosPago: [],
    cuponesAgrupados: [],
    incluyeCategoriasEspecificas: [],
    excluyeCategoriasEspecificas: [],
}

export default (state: any = INIT_STATE, action: any) => {
    switch (action?.type) {

        case SET_TIPO: {
            return {
                ...INIT_STATE,
                tipoCupon: action?.payload,
                cuponesAgrupados: []
            }
        }

        case SET_CODIGO: {
            return {
                ...state,
                codigoCupon: action?.payload
            }
        }

        case SET_NOMBRE: {
            return {
                ...state,
                nombreCupon: action?.payload
            }
        }

        case SET_DESCRIPCION: {
            return {
                ...state,
                descripcionCupon: action?.payload
            }
        }

        case SET_TIPO_VALOR: {
            return {
                ...state,
                tipoValorCupon: action?.payload?.valor,
                tipoValorCuponTexto: action?.payload?.texto,
            }
        }

        case SET_VALOR_CUPON: {
            return {
                ...state,
                valorCupon: action?.payload
            }
        }

        case SET_FECHA_INICIO: {
            return {
                ...state,
                fechaInicioCupon: action?.payload
            }
        }

        case SET_FECHA_FIN: {
            return {
                ...state,
                fechaFinCupon: action?.payload
            }
        }

        case SET_CUPONES_LIMITADOS: {
            return {
                ...state,
                cuponesLimitados: action?.payload
            }
        }

        case SET_CANTIDAD_CUPONES: {
            return {
                ...state,
                cantidadCupones: action?.payload
            }
        }

        case SET_INCLUIR_REGLAS: {
            return {
                ...state,
                incluyeReglas: action?.payload
            }
        }

        case SET_VALIDO_TODAS_SUCURSALES: {
            return {
                ...state,
                validoTodasSucursales: action?.payload
            }
        }

        case SET_EXCLUSIVO_COMPRAS_ONLINE: {
            return {
                ...state,
                exclusivoComprasOnline: action?.payload
            }
        }

        case SET_TIPO_ENTREGA: {
            if (state?.tipoEntrega.includes(action?.payload)) {
                state.tipoEntrega = state?.tipoEntrega.filter((e: any) => e !== action?.payload)
            } else {
                state.tipoEntrega.push(action?.payload)
            }
            return {
                ...state,
            }
        }

        case SET_DIAS_SEMANA: {
            if (state?.diasSemana.includes(action?.payload)) {
                state.diasSemana = state?.diasSemana.filter((e: any) => e !== action?.payload)
            } else {
                state.diasSemana.push(action?.payload)
            }
            return {
                ...state,
            }
        }

        case SET_APLICA_BLOQUES_HORARIOS_COMPRA: {
            return {
                ...state,
                aplicaBloquesHorariosCompra: action?.payload,
                cantidadBloquesCompra: 1
            }
        }

        case SET_APLICA_BLOQUES_HORARIOS_ENTREGA: {
            return {
                ...state,
                aplicaBloquesHorariosEntrega: action?.payload,
                cantidadBloquesEntrega: 1
            }
        }

        case SET_AGREGAR_CANTIDAD_BLOQUES_COMPRA: {
            return {
                ...state,
                cantidadBloquesCompra: ((state?.cantidadBloquesCompra + 1) >= 3) ? 3 : (state?.cantidadBloquesCompra + 1)
            }
        }

        case SET_AGREGAR_CANTIDAD_BLOQUES_ENTREGA: {
            return {
                ...state,
                cantidadBloquesEntrega: ((state?.cantidadBloquesEntrega + 1) >= 3) ? 3 : (state?.cantidadBloquesEntrega + 1)
            }
        }

        case SET_ELIMINAR_CANTIDAD_BLOQUES_COMPRA: {
            state.bloqueHorarioCompra.splice((state?.cantidadBloquesCompra - 1), 1)

            return {
                ...state,
                cantidadBloquesCompra: ((state?.cantidadBloquesCompra - 1) <= 0) ? 0 : (state?.cantidadBloquesCompra - 1)
            }
        }

        case SET_ELIMINAR_CANTIDAD_BLOQUES_ENTREGA: {
            state.bloqueHorarioEntrega.splice((state?.cantidadBloquesEntrega - 1), 1)

            return {
                ...state,
                cantidadBloquesEntrega: ((state?.cantidadBloquesEntrega - 1) <= 0) ? 0 : (state?.cantidadBloquesEntrega - 1)
            }
        }

        case SET_HORA_INICIO_COMPRA: {
            state.bloqueHorarioCompra[action?.payload?.position] = { ...state.bloqueHorarioCompra[action?.payload?.position], inicio: action?.payload?.time }

            return {
                ...state,
            }
        }

        case SET_HORA_INICIO_ENTREGA: {
            state.bloqueHorarioEntrega[action?.payload?.position] = { ...state.bloqueHorarioEntrega[action?.payload?.position], inicio: action?.payload?.time }

            return {
                ...state,
            }
        }

        case SET_HORA_FIN_COMPRA: {
            state.bloqueHorarioCompra[action?.payload?.position] = { ...state.bloqueHorarioCompra[action?.payload?.position], fin: action?.payload?.time }

            return {
                ...state,
            }
        }

        case SET_HORA_FIN_ENTREGA: {
            state.bloqueHorarioEntrega[action?.payload?.position] = { ...state.bloqueHorarioEntrega[action?.payload?.position], fin: action?.payload?.time }

            return {
                ...state,
            }
        }

        case SET_CANAL: {
            if (state?.canal.includes(action?.payload)) {
                state.canal = state?.canal.filter((e: any) => e !== action?.payload)
            } else {
                state.canal.push(action?.payload)
            }

            return {
                ...state,
            }
        }

        case SET_MONTO_MINIMO: {
            return {
                ...state,
                montoMinimo: action?.payload
            }
        }

        case SET_APLICA_PRODUCTOS_ESPECIFICOS: {
            return {
                ...state,
                aplicaProductosEspecificos: action?.payload
            }
        }

        case SET_EXCLUIR_PRODUCTOS_ESPECIFICOS: {
            return {
                ...state,
                excluyeProductosEspecificos: action?.payload
            }
        }

        case SET_INCLUYE_PRODUCTOS: {
            if (state?.incluyeProductos.includes(action?.payload)) {
                state.incluyeProductos = state?.incluyeProductos.filter((e: any) => e !== action?.payload)
            } else {
                state.incluyeProductos.push(action?.payload)
            }

            return {
                ...state,
            }
        }

        case SET_EXCLUYE_PRODUCTOS: {
            if (state?.excluyeProductos.includes(action?.payload)) {
                state.excluyeProductos = state?.excluyeProductos.filter((e: any) => e !== action?.payload)
            } else {
                state.excluyeProductos.push(action?.payload)
            }

            return {
                ...state,
            }
        }

        case SET_RESET_INCLUYE_PRODUCTOS: {
            return {
                ...state,
                incluyeProductos: [],
                incluyeCategoriasEspecificas: [],
            }
        }

        case SET_RESET_EXCLUYE_PRODUCTOS: {
            return {
                ...state,
                excluyeProductos: [],
                excluyeCategoriasEspecificas: [],
            }
        }

        case SET_APLICA_MEDIOS_PAGO: {
            return {
                ...state,
                aplicaMediosPago: action?.payload
            }
        }

        case SET_MEDIOS_PAGO: {
            if (state?.mediosPago.includes(action?.payload)) {
                state.mediosPago = state?.mediosPago.filter((e: any) => e !== action?.payload)
            } else {
                state.mediosPago.push(action?.payload)
            }

            return {
                ...state,
            }
        }

        case SET_CUPON_AGRUPADO: {
            state.cuponesAgrupados[action?.payload?.position] = { ...state.cuponesAgrupados[action?.payload?.position], ...action?.payload?.cupon }
            return {
                ...state,
            }
        }

        case SET_ELIMINAR_CUPON_AGRUPADO: {
            state.cuponesAgrupados = state.cuponesAgrupados.filter((e: any) => e?.position !== action?.payload)

            return {
                ...state,
            }
        }

        case SET_RESET_CUPON: {
            state = {
                ...INIT_STATE,
            }

            return {
                ...state,
            }
        }

        case SET_ES_RESTRICTIVO: {
            return {
                ...state,
                esRestrictivo: action?.payload,
            }
        }

        case SET_APLICA_FECHAS_HORAS_COMPRA_ESPECIFICAS: {
            return {
                ...state,
                aplicaFechasHoraCompraEspecificas: action?.payload,
                aplicaBloquesHorariosCompra: false,
                aplicaFechasCompraEspecificas: false,
            }
        }

        case SET_APLICA_FECHAS_HORAS_ENTREGA_ESPECIFICAS: {
            return {
                ...state,
                aplicaFechasHoraEntregaEspecificas: action?.payload,
                aplicaBloquesHorariosEntrega: false,
                aplicaFechasEntregaEspecificas: false,
            }
        }

        case SET_APLICA_FECHAS_COMPRA_ESPECIFICAS: {
            return {
                ...state,
                aplicaFechasCompraEspecificas: action?.payload,
                fechasCompraEspecificas: [],
                cantidadFechasCompraEspecificas: 1
            }
        }

        case SET_APLICA_FECHAS_ENTREGA_ESPECIFICAS: {
            return {
                ...state,
                aplicaFechasEntregaEspecificas: action?.payload,
                fechasEntregaEspecificas: [],
                cantidadFechasEntregaEspecificas: 1
            }
        }

        case SET_AGREGAR_FECHA_COMPRA_ESPECIFICA: {
            return {
                ...state,
                cantidadFechasCompraEspecificas: ((state?.cantidadFechasCompraEspecificas + 1) >= 10) ? 10 : (state?.cantidadFechasCompraEspecificas + 1)
            }
        }

        case SET_AGREGAR_FECHA_ENTREGA_ESPECIFICA: {
            return {
                ...state,
                cantidadFechasEntregaEspecificas: ((state?.cantidadFechasEntregaEspecificas + 1) >= 10) ? 10 : (state?.cantidadFechasEntregaEspecificas + 1)
            }
        }

        case SET_ELIMINAR_FECHA_COMPRA_ESPECIFICA: {
            state.fechasCompraEspecificas.splice((state?.cantidadFechasCompraEspecificas - 1), 1)

            return {
                ...state,
                cantidadFechasCompraEspecificas: ((state?.cantidadFechasCompraEspecificas - 1) <= 0) ? 0 : (state?.cantidadFechasCompraEspecificas - 1)
            }
        }

        case SET_ELIMINAR_FECHA_ENTREGA_ESPECIFICA: {
            state.fechasEntregaEspecificas.splice((state?.cantidadFechasEntregaEspecificas - 1), 1)

            return {
                ...state,
                cantidadFechasEntregaEspecificas: ((state?.cantidadFechasEntregaEspecificas - 1) <= 0) ? 0 : (state?.cantidadFechasEntregaEspecificas - 1)
            }
        }

        case SET_FECHA_COMPRA_ESPECIFICA: {
            if (state?.fechasCompraEspecificas.includes(action?.payload)) {
                state.fechasCompraEspecificas = state?.fechasCompraEspecificas.filter((e: any) => e !== action?.payload)
            } else {
                state.fechasCompraEspecificas.push(action?.payload)
            }

            return {
                ...state,
            }
        }

        case SET_FECHA_ENTREGA_ESPECIFICA: {
            if (state?.fechasEntregaEspecificas.includes(action?.payload)) {
                state.fechasEntregaEspecificas = state?.fechasEntregaEspecificas.filter((e: any) => e !== action?.payload)
            } else {
                state.fechasEntregaEspecificas.push(action?.payload)
            }

            return {
                ...state,
            }
        }

        case SET_INCLUYE_CATEGORIAS: {
            if (state?.incluyeCategoriasEspecificas.includes(action?.payload)) {
                state.incluyeCategoriasEspecificas = state?.incluyeCategoriasEspecificas.filter((e: any) => e !== action?.payload)
            } else {
                state.incluyeCategoriasEspecificas.push(action?.payload)
            }

            return {
                ...state,
            }
        }

        case SET_EXCLUYE_CATEGORIAS: {
            if (state?.excluyeCategoriasEspecificas.includes(action?.payload)) {
                state.excluyeCategoriasEspecificas = state?.excluyeCategoriasEspecificas.filter((e: any) => e !== action?.payload)
            } else {
                state.excluyeCategoriasEspecificas.push(action?.payload)
            }

            return {
                ...state,
            }
        }

        case SET_LIMIT_EDIT_CUPON: {
            return {
                ...state,
                Agil: action?.payload
            }
        }
        case SET_EDIT_DATA_CUPON: {
            let cuponesAgrupados = []
            if ((action?.payload?.cupones && action?.payload?.cupones.length > 0)) {
                action?.payload?.cupones.map((e: any, i: number) => {
                    cuponesAgrupados.push({
                        idCupon: e?.Id,
                        codigoCupon: e?.Codigo,
                        tipoValorCupon: e?.tipoValor?.Id,
                        tipoValorCuponTexto: e?.tipoValor?.Nombre,
                        valorCupon: e?.Valor,
                        diasExpiracionCupon: e?.DiasExpiracion,
                        position: i,
                        montoMinimo: e?.montoMinimo,
                    })
                })
            }
            state = {
                idCupon: (action?.payload?.Id) ? action?.payload?.Id : action?.payload?.CodigoAgrupacion,
                Agil: (action?.payload?.Agil) ? action?.payload?.Agil : false,
                codigoAgrupacion: (action?.payload?.CodigoAgrupacion) ? action?.payload?.CodigoAgrupacion : null,
                tipoCupon: (action?.payload?.IdTipoAgrupacion === null) ? 'SIMPLE' : (action?.payload?.IdTipoAgrupacion === 1) ? 'BIENVENIDA' : 'AGRUPADOS',
                codigoCupon: (action?.payload?.Codigo) ? action?.payload?.Codigo : null,
                nombreCupon: (action?.payload?.Nombre) ? action?.payload?.Nombre : null,
                descripcionCupon: (action?.payload?.Descripcion) ? action?.payload?.Descripcion : null,
                tipoValorCupon: (action?.payload?.IdTipoValor) ? action?.payload?.IdTipoValor : null,
                tipoValorCuponTexto: (action?.payload?.tipoValor && action?.payload?.tipoValor?.Nombre) ? action?.payload?.tipoValor?.Nombre : null,
                valorCupon: (action?.payload?.Valor) ? action?.payload?.Valor : 0,
                fechaInicioCupon: (action?.payload?.FechaInicio) ? moment(action?.payload?.FechaInicio).utc(false).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
                fechaFinCupon: (action?.payload?.FechaExpiracion) ? moment(action?.payload?.FechaExpiracion).utc(false).format('YYYY-MM-DD') : null,
                cuponesLimitados: (action?.payload?.Cantidad !== null && action?.payload?.Cantidad > 0) ? true : false,
                cantidadCupones: (action?.payload?.Cantidad !== null && action?.payload?.Cantidad > 0) ? action?.payload?.Cantidad : 0,
                validoTodasSucursales: (action?.payload?.IdSucursal) ? false : true,
                exclusivoComprasOnline: true,
                cuponesAgrupados,
                esRestrictivo: (action?.payload?.esRestrictivo) ? action?.payload?.esRestrictivo : false,

                // REGLAS
                incluyeReglas: (action?.payload?.reglas.length > 0) ? true : false,

                tipoEntrega: (action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_TIPO_ENTREGA)?.Valor) ?
                    action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_TIPO_ENTREGA)?.Valor.split(',').map(Number)
                    :
                    [ID_TIPO_ENTREGA_DELIVERY, ID_TIPO_ENTREGA_RETIRO],

                diasSemana: (action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_DIAS_SEMANA)?.Valor) ?
                    action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_DIAS_SEMANA)?.Valor.split(',')
                    :
                    ['L', 'M', 'X', 'J', 'V', 'S', 'D'],

                canal: (action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_CANAL)?.Valor) ?
                    action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_CANAL)?.Valor.split(',')
                    :
                    [TIPO_CANAL_WEB, TIPO_CANAL_APP],

                montoMinimo: (action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_MONTO_MINIMO_SUBTOTAL)?.Valor) ?
                    parseInt(action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_MONTO_MINIMO_SUBTOTAL)?.Valor)
                    :
                    0,

                aplicaProductosEspecificos: (action?.payload?.reglas.find((e: any) =>
                    e?.IdTipo === REGLA_INCLUYE_PRODUCTOS
                    || e?.IdTipo === REGLA_INCLUYE_CATEGORIAS
                )?.Valor) ?
                    true
                    :
                    false,

                excluyeProductosEspecificos: (action?.payload?.reglas.find((e: any) =>
                    e?.IdTipo === REGLA_EXCLUYE_PRODUCTOS
                    || e?.IdTipo === REGLA_EXCLUYE_PRODUCTOS
                )?.Valor) ?
                    true
                    :
                    false,

                incluyeProductos: (action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_INCLUYE_PRODUCTOS)?.Valor) ?
                    action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_INCLUYE_PRODUCTOS)?.Valor.split(',').map(Number)
                    :
                    [],

                excluyeProductos: (action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_EXCLUYE_PRODUCTOS)?.Valor) ?
                    action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_EXCLUYE_PRODUCTOS)?.Valor.split(',').map(Number)
                    :
                    [],

                aplicaMediosPago: (action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_INCLUYE_MEDIOS_PAGO)?.Valor) ? true : false,

                mediosPago: (action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_INCLUYE_MEDIOS_PAGO)?.Valor) ?
                    action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_INCLUYE_MEDIOS_PAGO)?.Valor.split(',').map(Number)
                    :
                    [],

                incluyeCategoriasEspecificas: (action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_INCLUYE_CATEGORIAS)?.Valor) ?
                    action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_INCLUYE_CATEGORIAS)?.Valor.split(',').map(Number)
                    :
                    [],

                excluyeCategoriasEspecificas: (action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_EXCLUYE_CATEGORIAS)?.Valor) ?
                    action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_EXCLUYE_CATEGORIAS)?.Valor.split(',').map(Number)
                    :
                    [],

                aplicaFechasHoraCompraEspecificas: (action?.payload?.reglas.find((e: any) =>
                    e?.IdTipo === REGLA_BLOQUE_HORARIO_COMPRA
                    || e?.IdTipo === REGLA_FECHA_ESPECIFICA_COMPRA
                )?.Valor) ?
                    true
                    :
                    false,

                aplicaFechasHoraEntregaEspecificas: (action?.payload?.reglas.find((e: any) =>
                    e?.IdTipo === REGLA_BLOQUE_HORARIO_ENTREGA
                    || e?.IdTipo === REGLA_FECHA_ESPECIFICA_ENTREGA
                )?.Valor) ?
                    true
                    :
                    false,

                aplicaFechasCompraEspecificas: (action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_FECHA_ESPECIFICA_COMPRA)?.Valor) ?
                    true
                    :
                    false,

                aplicaFechasEntregaEspecificas: (action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_FECHA_ESPECIFICA_ENTREGA)?.Valor) ?
                    true
                    :
                    false,

                cantidadFechasCompraEspecificas: (action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_FECHA_ESPECIFICA_COMPRA)?.Valor) ?
                    action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_FECHA_ESPECIFICA_COMPRA)?.Valor.split(',').length
                    :
                    1,

                cantidadFechasEntregaEspecificas: (action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_FECHA_ESPECIFICA_ENTREGA)?.Valor) ?
                    action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_FECHA_ESPECIFICA_ENTREGA)?.Valor.split(',').length
                    :
                    1,

                fechasCompraEspecificas: (action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_FECHA_ESPECIFICA_COMPRA)?.Valor) ?
                    action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_FECHA_ESPECIFICA_COMPRA)?.Valor.split(',')
                    :
                    [],

                fechasEntregaEspecificas: (action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_FECHA_ESPECIFICA_ENTREGA)?.Valor) ?
                    action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_FECHA_ESPECIFICA_ENTREGA)?.Valor.split(',')
                    :
                    [],

                aplicaBloquesHorariosCompra: (action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_BLOQUE_HORARIO_COMPRA)?.Valor) ?
                    true
                    :
                    false,

                aplicaBloquesHorariosEntrega: (action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_BLOQUE_HORARIO_ENTREGA)?.Valor) ?
                    true
                    :
                    false,

                cantidadBloquesCompra: (action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_BLOQUE_HORARIO_COMPRA)?.Valor) ?
                    JSON.parse(action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_BLOQUE_HORARIO_COMPRA)?.Valor).length
                    :
                    1,

                cantidadBloquesEntrega: (action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_BLOQUE_HORARIO_ENTREGA)?.Valor) ?
                    JSON.parse(action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_BLOQUE_HORARIO_ENTREGA)?.Valor).length
                    :
                    1,

                bloqueHorarioCompra: (action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_BLOQUE_HORARIO_COMPRA)?.Valor) ?
                    JSON.parse(action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_BLOQUE_HORARIO_COMPRA)?.Valor)
                    :
                    [],

                bloqueHorarioEntrega: (action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_BLOQUE_HORARIO_ENTREGA)?.Valor) ?
                    JSON.parse(action?.payload?.reglas.find((e: any) => e?.IdTipo === REGLA_BLOQUE_HORARIO_ENTREGA)?.Valor)
                    :
                    [],
            }

            return {
                ...state,
            }
        }

        default:
            return state
    }
}