import React from 'react'
import {AvatarContainer, CardContainer, CardSection, KpiDescribeSection} from "./CardProductInsight.style";
import {Avatar, Tooltip} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import {AnalisisHorarioContainer, DayContainer, DayName, DayValue} from "../../Clientes/CardCliente/CardCliente.style";
import {Brightness1, Brightness4, Brightness6, Brightness7, NightsStay} from "@material-ui/icons";
import {amber, blueGrey, grey, teal, yellow} from "@material-ui/core/colors";
import {Button} from "@material-ui/core/";


export const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 14,
    },
}))(Tooltip);

const CardProductInsight = ({ product, formatCurrency, launchProductModal }) => {

    const diasEntrega = product.DiasPreferenciaEntrega.split(",")
    const preferenceColorLevel = [teal[900], teal[700], teal[500], teal[400], teal[300], teal[200], teal[100]]

    const parseDayName = { lun: "lunes", mar: "martes", mie: "miercoles", jue: "jueves", vie: "viernes", sab: "sabado", dom: "domingo"}


    return (
        <CardContainer>
            <CardSection>
                <AvatarContainer>
                    <Avatar src={product.ImagenXS}/>
                </AvatarContainer>
                <p>{decodeURIComponent(product.Nombre)}</p>
            </CardSection>
            <CardSection>
                <LightTooltip title={`Los clientes prefieren este producto habitualmente los días ${parseDayName[product.DiaPreferenciaEntrega]} y frecuentemente en ${product.JornadaPreferenciaEntrega === "Mediodía" ? "el" : "la"} ${product.JornadaPreferenciaEntrega.toLowerCase()}`}>
                    <AnalisisHorarioContainer>
                        <DayContainer style={{marginRight: 8}}>
                            <DayValue color={"none"} >
                                {
                                    product.JornadaPreferenciaEntrega === "Madrugada" && <NightsStay style={{color: blueGrey[700]}}/>
                                }
                                {
                                    product.JornadaPreferenciaEntrega === "Mañana" && <Brightness6 style={{color: amber[300]}}/>
                                }
                                {
                                    product.JornadaPreferenciaEntrega === "Mediodía" && <Brightness7 style={{color: yellow[600]}}/>
                                }
                                {
                                    product.JornadaPreferenciaEntrega === "Tarde" && <Brightness4 style={{color: yellow[800]}}/>
                                }
                                {
                                    product.JornadaPreferenciaEntrega === "Noche" && <Brightness1 style={{color: grey[700]}}/>
                                }
                            </DayValue>
                            <DayName>{product.JornadaPreferenciaEntrega}</DayName>
                        </DayContainer>
                        <DayContainer>
                            <DayValue color={preferenceColorLevel[diasEntrega.indexOf("lun")]}/>
                            <DayName>L</DayName>
                        </DayContainer>
                        <DayContainer>
                            <DayValue color={preferenceColorLevel[diasEntrega.indexOf("mar")]}/>
                            <DayName>M</DayName>
                        </DayContainer>
                        <DayContainer >
                            <DayValue color={preferenceColorLevel[diasEntrega.indexOf("mie")]}/>
                            <DayName>M</DayName>
                        </DayContainer>
                        <DayContainer >
                            <DayValue color={preferenceColorLevel[diasEntrega.indexOf("jue")]}/>
                            <DayName>J</DayName>
                        </DayContainer>
                        <DayContainer >
                            <DayValue color={preferenceColorLevel[diasEntrega.indexOf("vie")]}/>
                            <DayName>V</DayName>
                        </DayContainer>
                        <DayContainer color={preferenceColorLevel[diasEntrega.indexOf("sab")]}>
                            <DayValue />
                            <DayName>S</DayName>
                        </DayContainer>
                        <DayContainer >
                            <DayValue color={preferenceColorLevel[diasEntrega.indexOf("dom")]}/>
                            <DayName>D</DayName>
                        </DayContainer>
                    </AnalisisHorarioContainer>
                </LightTooltip>
            </CardSection>
            <CardSection>
                <KpiDescribeSection>
                    <LightTooltip title={"Compra histórica del producto, desde la primera venta hasta el día actual"}>
                        <p><strong>HIST:</strong> {formatCurrency(product.VentaHistorica)} ({formatCurrency(product.CantidadHistorico, true)} items en {formatCurrency(product.PedidosHistorico, true)} pedidos)</p>
                    </LightTooltip>
                    <LightTooltip title={"Compra de los úlrimos 365 días"}>
                        <p><strong>LY:</strong> {formatCurrency(product.VentaLY)} ({formatCurrency(product.CantidadLY, true)} items en {formatCurrency(product.PedidosLY, true)} pedidos)</p>
                    </LightTooltip>
                    <LightTooltip title={"Compra desde el primer día del mes hasta el día actual"}>
                        <p><strong>MTD:</strong> {formatCurrency(product.VentaMTD)} ({formatCurrency(product.CantidadMTD, true)} items en {formatCurrency(product.PedidosMTD, true)} pedidos)</p>
                    </LightTooltip>
                    <LightTooltip title={"Compra desde el primer día del año hasta el día actual"}>
                        <p><strong>YTD:</strong> {formatCurrency(product.VentaYTD)} ({formatCurrency(product.CantidadYTD, true)} items en {formatCurrency(product.PedidosYTD, true)} pedidos)</p>
                    </LightTooltip>
                </KpiDescribeSection>
            </CardSection>
            <CardSection style={{marginTop: 20}}>
                <Button variant={"contained"} size={"small"} onClick={() => launchProductModal(product)}>Más información</Button>
            </CardSection>
        </CardContainer>
    )
}

export default CardProductInsight