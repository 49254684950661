import React, { lazy, Suspense, createContext } from "react"
import { useSelector } from 'react-redux'
import { Route, Switch, Redirect } from "react-router-dom"
import {
    PRODUCTS,
    CATEGORY,
    BRANDS,
    DASHBOARD,
    ORDERS,
    SETTINGS,
    CONFIG,
    CUSTOMERS,
    COUPONS,
    STUFF_MEMBERS,
    USERS,
    SCHEDULES,
    SITE_SETTINGS,
    SELLS,
    FINANCE,
    REPORTS,
    INVOICES,
    INVOICE_DETAIL,
    PAYMENTDETAILS,
    MENU,
    OPCION,
    GEOZONA,
    FAVOURITE_RIDERS,
    PRODUCTS_PROMOTIONS,
    REGISTRO_COMERCIO,
    USUARIO_PERFIL,
    CUPONES,
    STORE_DESIGN,
    WEB,
    SUCURSAL_MESAS,
    LOGS_USER,
    POINTS_PROGRAM,
    CONFI_SHOP,
    PAGINAS_SHOP,
    NFC_ELEMENTOS,
    NFC_TRABAJADORES,
    NFC_INSPECCIONES,
    CLIENTES,
    IMPORTAR_CLIENTES,
    COMPONENT_PAGE,
    MARKETING,
    MARKETING_PLANTILLA,
    MARKETING_AUTOMATIZACION,
    MARKETING_AUDIENCIA,
    MARKETING_CAMPANIA,
    FORMULARIO,
    MARKETING_SALEBOT,
    PRODUCTO_PROMOCIONES,
    MARKETPLACES,
    PRODUCTS_INSIGHTS,
    SALESETUP,
    MARKETPLACES_PRODUCT,
    MARKETPLACES_AJUSTES,
    CONFIG_POS,
    PRODUCTO_SINCRONIZACION,
    BENEFICIOS_COMPRA
} from "./settings/constants"
import { InLineLoader } from "./components/InlineLoader/InlineLoader"
import AgilAuth from "./AgilAuth"
import Clientes from "./containers/Clientes/Clientes"
import ProductsInsights from "./containers/ProductsInsights/ProductsInsights"

const PageComponent = lazy(() => import('./containers/SettingPages/PageComponent/PageComponent'))
const LogsUser = lazy(() => import('./containers/LogsUser/LogsUser'))
const SettingWeb = lazy(() => import('./containers/SettingWeb/SettingWeb'))
const Products = lazy(() => import("./containers/Products/Products"))
const BulkLoad = lazy(() => import("./containers/Products/BulkLoad"))
const AdminLayout = lazy(() => import("./containers/Layout/Layout"))
const Home = lazy(() => import("./containers/Home/Home"))
const Category = lazy(() => import("./containers/Category/Category"))
const Brands = lazy(() => import("./containers/Brands/Brands"))
const Orders = lazy(() => import("./containers/Orders/Orders"))
const Settings = lazy(() => import("./containers/Settings"))
const Config = lazy(() => import("./containers/Config"))
const UsuarioPerfil = lazy(() => import("./containers/UsuarioPerfil"))
const Geozona = lazy(() => import("./containers/SucursalesGeoZona/geozona"))
const FavouriteRiders = lazy(() => import("./containers/FavouriteRiders/FavouriteRiders"))
const SiteSettingForm = lazy(() => import("./containers/SiteSettingForm/SiteSettingForm"))
const StaffMembers = lazy(() => import("./containers/StaffMembers/StaffMembers"))
const Users = lazy(() => import("./containers/Users/Users"))
const Schedules = lazy(() => import("./containers/Schedules/Schedules"))
const Customers = lazy(() => import("./containers/Customers/Customers"))
const Coupons = lazy(() => import("./containers/Coupons/Coupons"))
const Auth = lazy(() => import("./containers/Auth"))
const NotFound = lazy(() => import("./containers/NotFound/NotFound"))
const Sells = lazy(() => import("./containers/Sells/Sells"))
const Finance = lazy(() => import("./containers/Finance/finance"))
const Reports = lazy(() => import("./containers/Finance/reportes"))
const Invoices = lazy(() => import("./containers/Invoices/Invoices"))
const PaymentDetails = lazy(() => import("./containers/Invoices/PaymentDetails"))
const Menu = lazy(() => import("./containers/Menu/Menu"))
const Opcion = lazy(() => import("./containers/Opcion/Opcion"))
const Registro = lazy(() => import("./containers/Registro/Registro"))
const SucursalCupones = lazy(() => import("./containers/SucursalCupones"))
const CuponPedidos = lazy(() => import("./containers/SucursalCupones/Usage"))
const StoreDesign = lazy(() => import("./containers/StoreDesign/StoreDesign"))
const SucursalMesas = lazy(() => import("./containers/SucursalMesas"))
const PointsProgram = lazy(() => import("./containers/PointsProgram/PointsProgram"))
const ShopSetting = lazy(() => import("./containers/SettingWeb/ShopSetting"))
const SettingPages = lazy(() => import('./containers/SettingPages/SettingPages'))
const NFCElementos = lazy(() => import("./containers/ControlNFC/Elementos"))
const NFCTrabajadores = lazy(() => import("./containers/ControlNFC/Trabajadores"))
const NFCInspecciones = lazy(() => import("./containers/ControlNFC/Inspecciones"))
const MailTemplate = lazy(() => import('./containers/Marketing/MailTemplate/MailTemplate'))
const MailAutomatizacion = lazy(() => import('./containers/Marketing/Automatizacion/Automatizacion'))
const MarketingAudiencia = lazy(() => import('./containers/Marketing/Audiencia/Audiencia'))
const MarketingCampania = lazy(() => import('./containers/Marketing/Campania/Campania'))
const Formularios = lazy(() => import('./containers/Formularios/Formularios'))
const SaleBot = lazy(() => import('./containers/Marketing/SaleBot/SaleBot'))
const ProductoPromociones = lazy(() => import('./containers/ProductoPromociones'))
const Marketplace = lazy(() => import('./containers/Marketplace/Marketplace'))
const EcommerceSetting = lazy(() => import('./containers/Settings/Settings/Ecommerce.Setting'))
const AccountForm = lazy(() => import('./containers/Config/AccountForm'))
const MarketProduct = lazy(() => import('./containers/Marketplace/Productos/Productos'))
const MarketSetting = lazy(() => import('./containers/Marketplace/AjustesMarket/AjustesMarket'))
const SettingPos = lazy(() => import('./containers/SettingsPos/SettingsPos'))
const ImportClients = lazy(() => import('./containers/Clientes/ImportarClientes'))
const SincronizarProductos = lazy(() => import('./containers/SincronizarProductos'))
const BeneficioCompra = lazy(() => import('./containers/BeneficioCompra'))

/**
 *
 *  A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated.
 *
*/

export const HistoryContext = createContext({})

function PrivateRoute({ children, ...rest }) {
    const auth = useSelector<any, any>(state => state.auth)

    let isAuthenticated = !!auth.Token

    return (
        <Route
            {...rest}
            render={
                props => isAuthenticated ? (
                    <HistoryContext.Provider value={props}>{children}</HistoryContext.Provider>
                )
                    : (
                        <Redirect
                            to={{
                                pathname: "/auth",
                                state: { from: props.location }
                            }}
                        />
                    )
            }
        />
    )
}

const Routes = function () {

    return (
        //  <AuthProvider>
        <Suspense fallback={<InLineLoader />}>
            <Switch>
                <Route path={'/auth'}>
                    <Auth />
                </Route>
                {/* parche cache cliente */}
                <Route path={'/login'}>
                    <Auth />
                </Route>
                {/*  */}
                <PrivateRoute exact={true} path={DASHBOARD}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <Home />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={PRODUCTS}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <Products />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={'/products-bulk-load'}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <BulkLoad />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={CATEGORY}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <Category />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={BRANDS}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <Brands />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={ORDERS}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <Orders />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={CUSTOMERS}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <Customers />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={CLIENTES}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <Clientes />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={IMPORTAR_CLIENTES}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <ImportClients />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={POINTS_PROGRAM}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <PointsProgram />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={COUPONS}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <Coupons />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={LOGS_USER}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <LogsUser />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={SETTINGS}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <Settings />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={CONFIG}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <AccountForm />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={USUARIO_PERFIL}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <UsuarioPerfil />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={GEOZONA}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <Geozona />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={FAVOURITE_RIDERS}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <FavouriteRiders />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={PRODUCTS_PROMOTIONS}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <Products />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={STUFF_MEMBERS}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <StaffMembers />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={USERS}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <Users />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={SCHEDULES}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <Schedules />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={SITE_SETTINGS}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <SiteSettingForm />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={SELLS}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <Sells />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={PRODUCTS_INSIGHTS}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <ProductsInsights />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={FINANCE}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <Finance />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={REPORTS}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <Reports />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={INVOICE_DETAIL}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <Invoices />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={INVOICES}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <Invoices />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>

                <PrivateRoute path={PAYMENTDETAILS}>
                    <AdminLayout nosidebar >
                        <Suspense fallback={<InLineLoader />}>
                            <PaymentDetails />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={MENU}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <Menu />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={OPCION}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <Opcion />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={CUPONES}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <SucursalCupones />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={BENEFICIOS_COMPRA}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <BeneficioCompra />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={STORE_DESIGN}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <StoreDesign />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={'/cupon-uso/:IdCupon'}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <CuponPedidos />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={WEB}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <SettingWeb />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={CONFI_SHOP}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <ShopSetting />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={COMPONENT_PAGE}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <PageComponent />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={PAGINAS_SHOP}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <SettingPages />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={SUCURSAL_MESAS}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <SucursalMesas />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>

                <PrivateRoute path={NFC_ELEMENTOS}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <NFCElementos />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>

                <PrivateRoute path={NFC_TRABAJADORES}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <NFCTrabajadores />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>

                <PrivateRoute path={NFC_INSPECCIONES}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <NFCInspecciones />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>

                <PrivateRoute path={MARKETING}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <div>Marketing</div>
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={MARKETING_AUDIENCIA}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <MarketingAudiencia />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={MARKETING_PLANTILLA}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <MailTemplate />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={MARKETING_AUTOMATIZACION}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <MailAutomatizacion />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={MARKETING_CAMPANIA}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <MarketingCampania />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={FORMULARIO}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <Formularios />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={MARKETING_SALEBOT}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <SaleBot />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={PRODUCTO_PROMOCIONES}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <ProductoPromociones />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={PRODUCTO_SINCRONIZACION}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <SincronizarProductos />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={MARKETPLACES}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <Marketplace />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={SALESETUP}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <EcommerceSetting />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={MARKETPLACES_PRODUCT}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <MarketProduct />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={MARKETPLACES_AJUSTES}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <MarketSetting />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <PrivateRoute path={CONFIG_POS}>
                    <AdminLayout>
                        <Suspense fallback={<InLineLoader />}>
                            <SettingPos />
                        </Suspense>
                    </AdminLayout>
                </PrivateRoute>
                <Route path={REGISTRO_COMERCIO}>
                    <Registro />
                </Route>
                <Route component={NotFound} />
            </Switch>
        </Suspense>
        // </AuthProvider> 

    )
}

export default () => {
    return <AgilAuth>
        <Routes />
    </AgilAuth>
}
