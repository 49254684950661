import { combineReducers } from "redux"

import auth from "./auth/reducer"
import payment from "./payment/reducer"
import agrupaciones from "./promotions/reducer"
import coupons from "./coupons/reducer"
import productSync from "./productSync/reducer"
import beneficioCompra from "./beneficioCompra/reducer"

const RootReducer = history => {
  return combineReducers({
    auth,
    payment,
    agrupaciones,
    coupons,
    productSync,
    beneficioCompra,
  })
}

export default RootReducer
