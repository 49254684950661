import { EventEmitter } from '../utils/eventEmitter';
import axios from 'axios';
import { AppKey, AUTH_URL } from '../../config';

class jwtService extends EventEmitter {

    init() {
        this.setInterceptors();
        this.handleAuthentication();
    }

    setInterceptors = () => {
        axios.interceptors.request.use((config) => {
            config.params = config.params || {};
            config.params['app_key'] = AppKey;
            return config;
        });

        axios.interceptors.response.use(response => {
            return response;
        }, err => {
            return new Promise((resolve, reject) => {
                console.error(err)
                if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
                    // if you ever get an unauthorized response, logout the user
                    this.emit('onAutoLogout', 'Invalid access_token');
                    this.setSession(null);
                    
                    //HACER LOGOUT:
                    const returnUrl = `${window.location.origin}`;
                    const serverUrl = AUTH_URL;
                    const fullRedirectUrl = `${serverUrl}/logout?app_key=${encodeURI(AppKey)}&return_url=${encodeURIComponent(returnUrl)}&source_icon=${null && `${encodeURIComponent(null)}`}`;
                    window.location.replace(fullRedirectUrl)
                }
                throw err;
            });
        });
    };

    handleAuthentication = () => {
        let access_token = this.getAccessToken();

        if (access_token) {
            this.setSession(access_token);
            this.emit('onAutoLogin', access_token);
        } else {
            this.emit('onNoAccessToken');
        }
    };


    setSession = access_token => {
        if (access_token) {
            localStorage.setItem(AppKey, access_token);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
        }
        else {
            localStorage.removeItem(AppKey);
            delete axios.defaults.headers.common['Authorization'];
        }
    };

    logout = () => {
        this.setSession(null);
    };


    getAccessToken = () => {
        return window.localStorage.getItem(AppKey);
    };
}

const instance = new jwtService();

export default instance;
