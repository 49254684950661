import styled, {css} from "styled-components";
import {grey} from "@material-ui/core/colors";

export const CardContainer = styled.div`
  margin-bottom: 10px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  min-width: 400px;
  border: 1px solid ${grey[400]};
  border-radius: 10px;
  background-color: white;
  padding: 20px;
  
`

export const CardSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;



export const AvatarContainer = styled.div`
  
  .MuiAvatar-root {
    width: 110px;
    height: 110px;
    
  }
`;


export const KpiDescribeSection = styled.div`
  
    display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
    p {
      cursor: help;
      font-size: 14px;
      margin: 0;
    }
`;