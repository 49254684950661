import React, { useEffect, useState } from 'react'
import {
    ClientesBody,
    ClientesContainer,
    ClientesHeader,
    InfoContainer,
    ListadoClientes,
    LoadingClientes,
    NoClientes,
    PaginatorContainer,
    SearchContainer
} from "./Clientes.style"
import CardCliente, { LightTooltip } from "./CardCliente/CardCliente"
import axios from "axios"
import { LAMBDA_ADMIN, API_KEY_MAPS, LAMBDA_ALIADOS_13489AA } from "../../config"
import { useSelector } from 'react-redux'
import {
    Avatar,
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Card,
    ButtonGroup,
    Button,
    IconButton,
    Checkbox,
    FormControlLabel,
    Grid,
    Typography,
    useMediaQuery,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@material-ui/core"
import { AvatarBagde, AvatarContainer, NombreCliente } from "./CardCliente/CardCliente.style"
import { ArrowBack, Stars, WhatsApp, ExpandMore, FilterList } from "@material-ui/icons"
import currencyFormatterOptions from "../../config/currencyFormatterOptions.json"
import currencyFormatter from 'currency-formatter'
import { red } from "@material-ui/core/colors"
import { Pagination, Skeleton } from "@material-ui/lab"
import PopupOrderDetail from '../../utils/components/PopupOrderDetail'
import PopupShoppingBehaviour from './PopupShoppingBehaviour/PopupShoppingBehaviour'
import { useTheme } from '@material-ui/core/styles'

const gifLoading = 'https://s3.us-east-2.amazonaws.com/agil.public/general/user_profile_loading.gif'
const gifNotFound = 'https://s3.us-east-2.amazonaws.com/agil.public/general/notfound.gif'

const Clientes = () => {
    const theme = useTheme()
    const MediaQuery = useMediaQuery(theme.breakpoints.up('md'))
    const IdComercio = useSelector<any, any>(state => state?.auth?.payload?.usuarioPerfil?.sucursal?.IdComercio)
    const IdSucursal = useSelector<any, any>(state => state?.auth?.payload?.usuarioPerfil?.sucursal?.IdSucursal)
    const IdUsuario = useSelector<any, any>(state => state?.auth.auth.IdUsuario)
    const Moneda = useSelector<any, any>(state => state?.auth.payload.usuarioPerfil.sucursal.comercio.nacionalidad.Moneda)
    const [clientes, setClientes] = useState([])
    const [programaPuntos, setProgramaPuntos] = useState(null)
    const [showModalCliente, setShowModalCliente] = useState(false)
    const [showModalPuntos, setShowModalPuntos] = useState(false)
    const [showModalVentas, setShowModalVentas] = useState(false)
    const [selectedCliente, setSelectedCliente] = useState(null)
    const [loading, setLoading] = useState(false)
    const [initialLoading, setInitialLoading] = useState(true)
    const [loadingPuntos, setLoadingPuntos] = useState(false)
    const [billeteraPuntos, setBilleteraPuntos] = useState(null)
    const [transaccionesBilletera, setTransaccionesBilletera] = useState(null)
    const [isAddingPoints, setIsAddingPoints] = useState(false)
    const [monto, setMonto] = useState("")
    const [descripcion, setDescripcion] = useState("")
    const [searchText, setSearchText] = useState("")
    const [page, setPage] = useState(1)
    const [numPages, setNumPages] = useState(1)
    const [modalOrderDetail, setModalOrderDetail] = useState({ show: false, id: null })
    const [paySuccessful, setPaySuccessful] = useState(true)
    const [selectedUserType, setSelectedUserType] = useState('AMBOS')
    const [selectedSaleType, setSelectedSaleType] = useState('MAYOR_CERO')
    const [expanded, setExpanded] = useState(true)

    const usersTypes = [{
        title: (MediaQuery) ? 'Sólo Registrados' : 'Registrados',
        key: 'NO_IMPORTADOS',
    }, {
        title: (MediaQuery) ? <span style={{ marginLeft: 10, marginRight: 10 }}>Todos</span> : 'Todos',
        key: 'AMBOS',
    }, {
        title: (MediaQuery) ? 'Sólo Importados' : 'Importados',
        key: 'IMPORTADOS',
    }]

    const salesTypes = [{
        title: (MediaQuery) ? 'Al menos 1 compra' : '1+ compras',
        key: 'MAYOR_CERO',
    }, {
        title: (MediaQuery) ? <span style={{ marginLeft: 10, marginRight: 10 }}>Todas</span> : 'Todas',
        key: 'AMBAS',
    }, {
        title: (MediaQuery) ? 'Cero compras' : '0 compras',
        key: 'CERO',
    }]

    useEffect(() => {
        getClientes()
    }, [page, selectedUserType, selectedSaleType])

    useEffect(() => {
        const timer = setTimeout(() => {
            getClientes(true)
        }, 800)

        return () => {
            clearTimeout(timer)
        }
    }, [searchText])

    const getClientes = async (resetPage = false) => {
        setLoading(true)

        let pageNum = page
        if (resetPage) {
            setPage(1)
            pageNum = 1
        }

        const whereSearch = (searchText) ? `&searchText=${encodeURI(searchText)}` : ``
        const whereUser = (selectedUserType) ? `&mostrarUsuarios=${selectedUserType}` : ``
        const whereSale = (selectedSaleType) ? `&conCompras=${selectedSaleType}` : ``
        const response = await axios.get(`${LAMBDA_ALIADOS_13489AA}/clientes/getAllComercioCustomers/${IdComercio}?page=${pageNum}${whereSearch}${whereUser}${whereSale}`)
        const {
            clientes,
            programaPuntos,
            pagination
        } = response.data

        setClientes(clientes)
        setNumPages(pagination.pages)
        setProgramaPuntos(programaPuntos)
        setLoading(false)
        setInitialLoading(false)
    }

    useEffect(() => {
        if (showModalPuntos && selectedCliente) {
            getPuntosCliente()
        }
    }, [showModalPuntos, selectedCliente])

    const getPuntosCliente = async () => {
        setLoadingPuntos(true)
        setTransaccionesBilletera(null)
        setBilleteraPuntos(null)
        const response = await axios.get(`${LAMBDA_ADMIN}/getPuntosUsuario/${IdComercio}/${selectedCliente.IdCliente}`)

        const {
            billetera,
            transacciones
        } = response.data

        setTransaccionesBilletera(transacciones)
        setBilleteraPuntos(billetera)
        setLoadingPuntos(false)
    }

    const addPuntosCliente = async () => {
        if (!canSubmitPoints()) {
            return
        }

        setLoadingPuntos(true)
        await axios.post(
            `${LAMBDA_ADMIN}/addPuntosBilletera`,
            {
                IdUsuario: selectedCliente.IdCliente,
                IdUsuarioAutorizador: IdUsuario,
                Descripcion: descripcion,
                IdSucursal,
                Monto: monto
            }
        )

        await getPuntosCliente()
        setIsAddingPoints(false)
        setLoadingPuntos(false)
    }

    const launchAction = (event, client) => {
        return () => {
            setSelectedCliente(client)
            setShowModalCliente(false)
            setShowModalVentas(false)
            setShowModalPuntos(false)

            switch (event) {
                case "info":
                    setShowModalCliente(true)
                    break
                case "sale":
                    setShowModalVentas(true)
                    break
                case "points":
                    setShowModalPuntos(true)
                    break
                default:
            }
        }
    }

    const getGoogleStaticImage = (lat, lng) => {
        return `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=16&size=600x200&maptype=roadmap&markers=color:red%7C${lat},${lng}&key=${API_KEY_MAPS}`
    }

    const goToWhatsapp = (phone) => {
        const enlace = `https://api.whatsapp.com/send?phone=${phone}`
        window.open(enlace)
    }

    const formatCurrency = (value, hideSymbol = false) => {
        if (hideSymbol) {
            return currencyFormatter.format(value, { ...currencyFormatterOptions[Moneda], symbol: "" })
        }
        return currencyFormatter.format(value, { ...currencyFormatterOptions[Moneda] })
    }

    const enableAddPoints = () => {
        setIsAddingPoints(true)
        setMonto("")
        setDescripcion("")
    }

    const canSubmitPoints = () => {
        return descripcion.length > 10 && Math.abs(parseFloat(monto)) > 0
    }

    const filterTransaccionesBilletera = (row) => {
        if (paySuccessful) {
            return row.Confirmado === true
        }
        return row
    }

    const SkeletonHeader = () => {
        return (
            <div style={{ margin: 20 }}>
                <div style={{ margin: 20 }}>
                    <Grid container spacing={3}>
                        <Grid item md={6} sm={12} xs={12}>
                            <Skeleton variant="rect" width={'25%'} height={30} />
                            <Skeleton variant="rect" width={'50%'} height={30} style={{ marginTop: 5 }} />
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <Skeleton variant="rect" width={'25%'} height={30} />
                            <Skeleton variant="rect" width={'50%'} height={30} style={{ marginTop: 5 }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Skeleton variant="rect" width={'100%'} height={50} />
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }

    const SkeletonCard = () => {
        return (
            <Card style={{ margin: 10 }}>
                <div style={{ margin: 20 }}>
                    <Grid container spacing={3}>
                        {
                            (MediaQuery) && (
                                <Grid item lg={1} md={12} />
                            )
                        }
                        <Grid item lg={1} md={2} sm={12} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Skeleton variant="circle" width={100} height={100} />
                        </Grid>
                        <Grid item lg={2} md={3} sm={12} xs={12}>
                            <div>
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                            </div>
                        </Grid>
                        {
                            (MediaQuery) && (
                                <Grid item lg={3} md={1} sm={12} xs={12} />
                            )
                        }
                        <Grid item lg={1} md={2} sm={6} xs={12}>
                            <Skeleton variant="rect" height={50} />
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                        </Grid>
                        {
                            (MediaQuery) && (
                                <Grid item lg={1} md={1} sm={12} xs={12} />
                            )
                        }
                        <Grid item lg={1} md={3} sm={6} xs={12}>
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                            <Skeleton variant="rect" height={50} />
                        </Grid>
                        {
                            (MediaQuery) && (
                                <Grid item lg={1} md={12} />
                            )
                        }
                    </Grid>
                </div>
            </Card>
        )
    }

    const showFilters = () => {
        setExpanded(!expanded)
    }

    return (
        <>
            <Card style={{ margin: 10 }}>
                {
                    (initialLoading) ?
                        <SkeletonHeader />
                        :
                        <Accordion square={true} expanded={expanded} onChange={showFilters}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                            >
                                <Typography
                                    variant='h6'
                                    style={{
                                        marginLeft: 20,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <FilterList style={{ marginRight: 10 }} />
                                    Filtros
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div style={{ marginLeft: (MediaQuery) ? 20 : 0, marginRight: (MediaQuery) ? 20 : 0 }}>
                                    <Grid container spacing={3}>
                                        <Grid item md={6} sm={12} xs={12}>
                                            <Typography variant='h6'>Tipo de cliente</Typography>
                                            <ButtonGroup color="primary" aria-label="">
                                                {
                                                    usersTypes.map((e, i) => {
                                                        return (
                                                            <Button
                                                                size={(MediaQuery) ? 'large' : 'small'}
                                                                variant={(selectedUserType === e?.key) ? 'contained' : 'outlined'}
                                                                key={i}
                                                                onClick={() => setSelectedUserType(e?.key)}
                                                            >
                                                                {e?.title}
                                                            </Button>
                                                        )
                                                    })
                                                }
                                            </ButtonGroup>
                                        </Grid>
                                        <Grid item md={6} sm={12} xs={12}>
                                            <Typography variant='h6'>Cantidad de ventas</Typography>
                                            <ButtonGroup color="primary" aria-label="">
                                                {
                                                    salesTypes.map((e, i) => {
                                                        return (
                                                            <Button
                                                                size={(MediaQuery) ? 'large' : 'small'}
                                                                variant={(selectedSaleType === e?.key) ? 'contained' : 'outlined'}
                                                                key={i}
                                                                onClick={() => setSelectedSaleType(e?.key)}
                                                            >
                                                                {e?.title}
                                                            </Button>
                                                        )
                                                    })
                                                }
                                            </ButtonGroup>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <SearchContainer>
                                                <TextField
                                                    size={(MediaQuery) ? 'medium' : 'small'}
                                                    variant="outlined"
                                                    label="Filtrar Cliente"
                                                    placeholder="Filtrar por nombre, apellido, correo, categoría del cliente o base importada"
                                                    value={searchText}
                                                    onChange={(evt) => setSearchText(evt.target.value)}
                                                    fullWidth
                                                    autoFocus
                                                />
                                            </SearchContainer>
                                        </Grid>
                                    </Grid>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                }
            </Card>

            {
                (initialLoading || loading) ?
                    <>
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                    </>
                    :
                    <div style={{ marginTop: 10 }}>
                        <ListadoClientes>
                            {
                                !showModalPuntos && clientes.map((cliente) => {
                                    return (
                                        <CardCliente
                                            key={`card-${cliente.IdCliente}`}
                                            cliente={cliente}
                                            programaPuntos={programaPuntos}
                                            launchAction={launchAction}
                                            formatCurrency={formatCurrency}
                                        />
                                    )
                                })
                            }
                            {
                                clientes.length <= 0 &&
                                <NoClientes>
                                    <img src={gifNotFound} />
                                    {
                                        searchText !== "" ?
                                            <p>¡No se han encontrado clientes que cumplan con el criterio de búsqueda!</p>
                                            :
                                            <p>¡No existe registro de clientes!</p>
                                    }

                                </NoClientes>
                            }
                        </ListadoClientes>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Pagination count={numPages} page={page} onChange={(evt, val) => { setPage(val) }} />
                        </div>
                    </div>
            }

            <ClientesContainer>
                {/* MODAL DETALLE PAGO PEDIDO */}
                <PopupOrderDetail
                    id={modalOrderDetail.id}
                    open={modalOrderDetail.show}
                    onClose={() => setModalOrderDetail({ show: false, id: null })}
                />

                {/* PERFIL USUARIO */}
                <Dialog open={showModalCliente} onClose={launchAction("off", null)} fullWidth>
                    <DialogContent>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                            {
                                selectedCliente &&
                                <>
                                    <AvatarContainer>
                                        <Avatar src={selectedCliente.Imagen} />
                                        <LightTooltip title={selectedCliente.badgeConf.info}>
                                            <AvatarBagde background={selectedCliente.badgeConf.background} color={selectedCliente.badgeConf.color}><Stars className={"star-badge"} /> {selectedCliente.badgeConf.text}</AvatarBagde>
                                        </LightTooltip>
                                    </AvatarContainer>
                                    <NombreCliente style={{ marginTop: 10 }}>{selectedCliente.nombreCliente}</NombreCliente>
                                    <InfoContainer>
                                        <p><a href={`mailto:${selectedCliente.Correo}`}>{selectedCliente.Correo}</a></p>
                                        {
                                            selectedCliente.Telefono &&
                                            <p><a href={`tel:+${selectedCliente.PrefijoTelefonico}${selectedCliente.Telefono}`}>+{selectedCliente.PrefijoTelefonico}{selectedCliente.Telefono}</a></p>
                                        }
                                        <IconButton onClick={() => goToWhatsapp(`+${selectedCliente.PrefijoTelefonico}${selectedCliente.Telefono}`)}><WhatsApp /></IconButton>
                                        {
                                            selectedCliente.DireccionHabitualCompra &&
                                            <>
                                                <p>{selectedCliente.DireccionHabitualCompra}</p>
                                                <p>A {selectedCliente.DistanciaMetrosClienteSucursal} de la sucursal más cercana ({selectedCliente.DistanciaSegundosClienteSucursal} apróximadamente)</p>
                                                <img className={"map"} src={getGoogleStaticImage(selectedCliente.LatitudDireccionPrincipal, selectedCliente.LongitudDireccionPrincipal)} />
                                            </>
                                        }
                                        <Button className={"close"} variant={"outlined"} onClick={launchAction("off", null)}>Cerrar</Button>
                                    </InfoContainer>
                                </>
                            }
                        </div>
                    </DialogContent>
                </Dialog>

                {/* PUNTOS */}
                <Dialog open={showModalPuntos} onClose={launchAction("off", null)} fullScreen>
                    <DialogTitle> {!isAddingPoints && <IconButton onClick={launchAction("off", null)}><ArrowBack /></IconButton>}Puntos Históricos</DialogTitle>
                    <DialogContent>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                {
                                    selectedCliente &&
                                    <>
                                        <AvatarContainer>
                                            <Avatar src={selectedCliente.Imagen} />
                                            <LightTooltip title={selectedCliente.badgeConf.info}>
                                                <AvatarBagde background={selectedCliente.badgeConf.background} color={selectedCliente.badgeConf.color}><Stars className={"star-badge"} /> {selectedCliente.badgeConf.text}</AvatarBagde>
                                            </LightTooltip>
                                        </AvatarContainer>
                                        <NombreCliente style={{ marginTop: 10 }}>{selectedCliente.nombreCliente}</NombreCliente>
                                        {
                                            billeteraPuntos &&
                                            <p>Puntos actuales: {formatCurrency(billeteraPuntos.Balance, false)}</p>
                                        }
                                        {
                                            !loadingPuntos && !isAddingPoints &&
                                            <Button color="primary" variant="contained" onClick={enableAddPoints}>Agregar Puntos</Button>
                                        }
                                    </>
                                }
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: 20 }}>
                                {
                                    loadingPuntos &&
                                    <LoadingClientes>
                                        <div className={"image-container"}>
                                            <img src={gifLoading} />
                                        </div>
                                        <p className={"title"}>Cargando Billetera de usuario seleccionado ...</p>
                                    </LoadingClientes>
                                }
                                {
                                    !loadingPuntos && !isAddingPoints &&
                                    <div>
                                        <FormControlLabel
                                            control={<Checkbox checked={paySuccessful} onChange={() => { setPaySuccessful(!paySuccessful) }} name="checkedA" />}
                                            label="Pagados exitosamente"
                                        />
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Transacción</TableCell>
                                                    <TableCell>Tipo</TableCell>
                                                    <TableCell>Pedido</TableCell>
                                                    <TableCell>Puntos</TableCell>
                                                    <TableCell>Balance</TableCell>
                                                    <TableCell>Autorizado Por</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    transaccionesBilletera && transaccionesBilletera.filter(filterTransaccionesBilletera).map((b) => {

                                                        return (
                                                            <TableRow key={`transaccion-${b.Id}`}>
                                                                <TableCell>#{b.Id}</TableCell>
                                                                <TableCell>{b.tipoTransaccion.Descripcion}</TableCell>
                                                                <TableCell>{b.pedido ? <a style={{ textDecoration: 'none' }} href="#" onClick={() => setModalOrderDetail({ show: true, id: b.pedido.IdPedido })}>{`#${b.pedido.Codigo}-${b.pedido.Corelativo}`}</a> : "No Aplica"}</TableCell>
                                                                {
                                                                    (b.tipoTransaccion.Codigo === "CANJE" || (b.tipoTransaccion.Codigo === "MANUAL" && b.Monto < 0)) &&
                                                                    <TableCell style={{ color: red[500] }}>(-{formatCurrency(Math.abs(b.Monto), false)})</TableCell>
                                                                }
                                                                {
                                                                    (b.tipoTransaccion.Codigo !== "CANJE" && !(b.tipoTransaccion.Codigo === "MANUAL" && b.Monto < 0)) &&
                                                                    <TableCell>{formatCurrency(b.Monto, false)}</TableCell>
                                                                }

                                                                <TableCell>{formatCurrency(b.NewWalletBalance, false)}</TableCell>
                                                                <TableCell>
                                                                    {
                                                                        b.usuario &&
                                                                        <>
                                                                            <p style={{ margin: 0, fontWeight: "bold" }}>{b.usuario.Nombre} {b.usuario.Apellido}</p>
                                                                            <p style={{ margin: 0 }}>{b.usuario.Email}</p>
                                                                            <p style={{ margin: 0 }}>Comentario: {b.Descripcion}</p>
                                                                        </>
                                                                    }
                                                                    {
                                                                        !b.usuario &&
                                                                        <p>Sistema</p>
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                                {
                                                    transaccionesBilletera && transaccionesBilletera.length <= 0 &&
                                                    <TableRow>
                                                        <TableCell colSpan={6}>La billetera aún no tiene transacciones</TableCell>
                                                    </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </div>
                                }
                                {
                                    isAddingPoints && !loadingPuntos &&
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <TextField
                                            value={monto}
                                            onChange={(evt) => setMonto(evt.target.value)}
                                            variant="outlined"
                                            label="Monto"
                                            autoFocus
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        {
                                            monto.length == 0 &&
                                            <p style={{ color: red[500] }}>*Monto es requerido</p>
                                        }
                                        <TextField
                                            value={descripcion}
                                            onChange={(evt) => setDescripcion(evt.target.value)}
                                            variant="outlined"
                                            label="Comentario"
                                            placeholder={"Ej: Premio por cliente favorito"}
                                            style={{
                                                marginTop: 10
                                            }}
                                        />
                                        {
                                            (descripcion.length < 10 && monto.length > 0 && descripcion !== "") &&
                                            <p style={{ color: red[500] }}>Es necesario que el comentario tenga mínimo 10 caráteres. Carácteres
                                                actuales: {descripcion.length}</p>
                                        }
                                        {
                                            descripcion.length == 0 &&
                                            <p style={{ color: red[500] }}>*Comentario es requerido</p>
                                        }
                                        <div style={{
                                            display: "flex",
                                            marginTop: 20,
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                style={{
                                                    marginRight: 5
                                                }}
                                                disabled={!canSubmitPoints()}
                                                onClick={addPuntosCliente}
                                            >Agregar Puntos</Button>
                                            <Button variant="outlined" onClick={() => setIsAddingPoints(false)}>Cancelar</Button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>

                {/* HISTORIAL */}
                <PopupShoppingBehaviour
                    selectedCliente={selectedCliente}
                    open={showModalVentas}
                    onClose={() => setShowModalVentas(false)}
                    isAddingPoints={isAddingPoints}
                    idComercio={IdComercio}
                />
            </ClientesContainer>
        </>
    )
}

export default Clientes