import styled from "styled-components";

export const InfoContainer = styled.div`
  
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
margin-top: 20px;


& p{
  margin-top: 4px;
  margin-bottom: 4px;
  text-align: center;
}

& .close {
margin-top: 20px;
}

& .map{
margin-top: 20px;
margin-bottom: 10px;
}
`;

export const ContainerGraf = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  min-width: 500px;

  @media (max-width: 1020px) {
    flex-direction: column;
  }
`