export const URL_EVALUACION = 'https://docs.google.com/forms/d/e/1FAIpQLSef0_OlKPcK88fYNrU-I71sjSfDv3ZElGzWlRADMfKfoSL5Vw/viewform'

export const ID_TIPO_ENTREGA_DELIVERY = 1
export const ID_TIPO_ENTREGA_RETIRO = 2

export const TIPO_CANAL_WEB = 'WEB'
export const TIPO_CANAL_APP = 'APP'

export const TIPO_FECHA_ESPECIFICA_COMPRA = 'COMPRA'
export const TIPO_FECHA_ESPECIFICA_ENTREGA = 'ENTREGA'

//IdMedioPago
export const ID_MEDIO_PAGO_ITS_FREE = -1
export const ID_MEDIO_PAGO_FLOW = 1
export const ID_MEDIO_PAGO_TBK_WEBPAY = 2
export const ID_MEDIO_PAGO_MERCADO_PAGO_PERU = 3
export const ID_MEDIO_PAGO_MERCADO_PAGO_CHILE = 4
export const ID_MEDIO_PAGO_TBK_ONECLICK = 5
export const ID_MEDIO_PAGO_CHEK = 6
export const ID_MEDIO_PAGO_MACH = 7
export const ID_MEDIO_PAGO_MULTICAJA = 8
export const ID_MEDIO_PAGO_SODEXO = 9
export const ID_MEDIO_PAGO_EDENRED = 10
export const ID_MEDIO_PAGO_AMIPASS = 11
export const ID_MEDIO_PAGO_FPAY = 12
export const ID_MEDIO_PAGO_EFECTIVO = 13
