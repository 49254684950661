import React from "react"
import { Provider } from "react-redux"
import ReactDOM from "react-dom"

import { store } from "./redux/store"

import { BrowserRouter } from "react-router-dom"
import { Client as Styletron } from "styletron-engine-atomic"
import { Provider as StyletronProvider } from "styletron-react"
import { BaseProvider } from "baseui"
import { ApolloProvider } from "@apollo/react-hooks"

import { theme } from "./theme"
import Routes from "./routes"
import ApolloClient from "apollo-boost"
import * as serviceWorker from "./serviceWorker"
import "./theme/global.css"
import "./custom.css"
import { graphQlUrl } from "./config"
const client = new ApolloClient({
    // uri: process.env.REACT_APP_API_URL,
    uri: graphQlUrl
})


const ReduxIdentifier = ({ children }) => {
    // const dispatch = useDispatch()

    // useEffect(() => {

    //     document.title = 'Administrador Comercio'
    //     // eslint-disable-next-line
    // }, [])

    return children
}

function App() {

    const engine = new Styletron()

    return (
        <Provider store={store}>
            <ReduxIdentifier>
                <ApolloProvider client={client as any}>
                    <StyletronProvider value={engine}>
                        <BaseProvider theme={theme}>
                            <BrowserRouter>
                                <Routes />
                            </BrowserRouter>
                        </BaseProvider>
                    </StyletronProvider>
                </ApolloProvider>
            </ReduxIdentifier>
        </Provider>
    )
}

ReactDOM.render(<App />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
