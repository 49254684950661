export const SET_TIPO = 'SET_TIPO'
export const SET_CODIGO = 'SET_CODIGO'
export const SET_NOMBRE = 'SET_NOMBRE'
export const SET_DESCRIPCION = 'SET_DESCRIPCION'
export const SET_TIPO_VALOR = 'SET_TIPO_VALOR'
export const SET_VALOR_CUPON = 'SET_VALOR_CUPON'
export const SET_FECHA_INICIO = 'SET_FECHA_INICIO'
export const SET_FECHA_FIN = 'SET_FECHA_FIN'
export const SET_CUPONES_LIMITADOS = 'SET_CUPONES_LIMITADOS'
export const SET_CANTIDAD_CUPONES = 'SET_CANTIDAD_CUPONES'
export const SET_INCLUIR_REGLAS = 'SET_INCLUIR_REGLAS'
export const SET_VALIDO_TODAS_SUCURSALES = 'SET_VALIDO_TODAS_SUCURSALES'
export const SET_EXCLUSIVO_COMPRAS_ONLINE = 'SET_EXCLUSIVO_COMPRAS_ONLINE'
export const SET_TIPO_ENTREGA = 'SET_TIPO_ENTREGA'
export const SET_DIAS_SEMANA = 'SET_DIAS_SEMANA'
export const SET_APLICA_BLOQUES_HORARIOS_COMPRA = 'SET_APLICA_BLOQUES_HORARIOS_COMPRA'
export const SET_APLICA_BLOQUES_HORARIOS_ENTREGA = 'SET_APLICA_BLOQUES_HORARIOS_ENTREGA'
export const SET_AGREGAR_CANTIDAD_BLOQUES_COMPRA = 'SET_AGREGAR_CANTIDAD_BLOQUES_COMPRA'
export const SET_AGREGAR_CANTIDAD_BLOQUES_ENTREGA = 'SET_AGREGAR_CANTIDAD_BLOQUES_ENTREGA'
export const SET_ELIMINAR_CANTIDAD_BLOQUES_COMPRA = 'SET_ELIMINAR_CANTIDAD_BLOQUES_COMPRA'
export const SET_ELIMINAR_CANTIDAD_BLOQUES_ENTREGA = 'SET_ELIMINAR_CANTIDAD_BLOQUES_ENTREGA'
export const SET_APLICA_FECHAS_COMPRA_ESPECIFICAS = 'SET_APLICA_FECHAS_COMPRA_ESPECIFICAS'
export const SET_APLICA_FECHAS_ENTREGA_ESPECIFICAS = 'SET_APLICA_FECHAS_ENTREGA_ESPECIFICAS'
export const SET_TIPO_FECHA_ESPECIFICA = 'SET_TIPO_FECHA_ESPECIFICA'
export const SET_FECHA_COMPRA_ESPECIFICA = 'SET_FECHA_COMPRA_ESPECIFICA'
export const SET_FECHA_ENTREGA_ESPECIFICA = 'SET_FECHA_ENTREGA_ESPECIFICA'
export const SET_AGREGAR_FECHA_COMPRA_ESPECIFICA = 'SET_AGREGAR_FECHA_COMPRA_ESPECIFICA'
export const SET_AGREGAR_FECHA_ENTREGA_ESPECIFICA = 'SET_AGREGAR_FECHA_ENTREGA_ESPECIFICA'
export const SET_ELIMINAR_FECHA_COMPRA_ESPECIFICA = 'SET_ELIMINAR_FECHA_COMPRA_ESPECIFICA'
export const SET_ELIMINAR_FECHA_ENTREGA_ESPECIFICA = 'SET_ELIMINAR_FECHA_ENTREGA_ESPECIFICA'
export const SET_HORA_INICIO_COMPRA = 'SET_HORA_INICIO_COMPRA'
export const SET_HORA_INICIO_ENTREGA = 'SET_HORA_INICIO_ENTREGA'
export const SET_HORA_FIN_COMPRA = 'SET_HORA_FIN_COMPRA'
export const SET_HORA_FIN_ENTREGA = 'SET_HORA_FIN_ENTREGA'
export const SET_CANAL = 'SET_CANAL'
export const SET_MONTO_MINIMO = 'SET_MONTO_MINIMO'
export const SET_APLICA_PRODUCTOS_ESPECIFICOS = 'SET_APLICA_PRODUCTOS_ESPECIFICOS'
export const SET_EXCLUIR_PRODUCTOS_ESPECIFICOS = 'SET_EXCLUIR_PRODUCTOS_ESPECIFICOS'
export const SET_INCLUYE_PRODUCTOS = 'SET_INCLUYE_PRODUCTOS'
export const SET_INCLUYE_CATEGORIAS = 'SET_INCLUYE_CATEGORIAS'
export const SET_EXCLUYE_PRODUCTOS = 'SET_EXCLUYE_PRODUCTOS'
export const SET_EXCLUYE_CATEGORIAS = 'SET_EXCLUYE_CATEGORIAS'
export const SET_RESET_INCLUYE_PRODUCTOS = 'SET_RESET_INCLUYE_PRODUCTOS'
export const SET_RESET_EXCLUYE_PRODUCTOS = 'SET_RESET_EXCLUYE_PRODUCTOS'
export const SET_APLICA_MEDIOS_PAGO = 'SET_APLICA_MEDIOS_PAGO'
export const SET_MEDIOS_PAGO = 'SET_MEDIOS_PAGO'
export const SET_CUPON_AGRUPADO = 'SET_CUPON_AGRUPADO'
export const SET_ELIMINAR_CUPON_AGRUPADO = 'SET_ELIMINAR_CUPON_AGRUPADO'
export const SET_RESET_CUPON = 'SET_RESET_CUPON'
export const SET_EDIT_DATA_CUPON = 'SET_EDIT_DATA_CUPON'
export const SET_LIMIT_EDIT_CUPON = 'SET_LIMIT_EDIT_CUPON'
export const SET_APLICA_FECHAS_HORAS_COMPRA_ESPECIFICAS = 'SET_APLICA_FECHAS_HORAS_COMPRA_ESPECIFICAS'
export const SET_APLICA_FECHAS_HORAS_ENTREGA_ESPECIFICAS = 'SET_APLICA_FECHAS_HORAS_ENTREGA_ESPECIFICAS'
export const SET_ES_RESTRICTIVO = 'SET_ES_RESTRICTIVO'