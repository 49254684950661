import React, { useContext } from "react"
import { useDispatch } from 'react-redux'

import axios from 'axios'
import { AppKey, AUTH_URL, LAMBDA_AUTH } from '../../config'
import authActions from '../../redux/auth/actions'
import jwtService from '../services/jwtService'
import { createContext } from 'react';
const AgilAuthContext = createContext({} as any);


export const AgilAuthProvider = ({ children }) => {
    const dispatch = useDispatch()

    const goToLogin = (preventLoop = false, sourceIconUrl = null) => {
        jwtService.setSession(null);

        let continueUrl = `${window.location.pathname}${window.location.search || ""}`;
        if (preventLoop) {
            continueUrl = `${window.location.origin}`;
        }
        const appKey = AppKey;
        const returnUrl = `${window.location.origin}/auth`;
        const serverUrl = AUTH_URL;
        const fullRedirectUrl = `${serverUrl}/signin?app_key=${encodeURI(appKey)}&return_url=${encodeURIComponent(returnUrl)}&continue_url=${encodeURIComponent(continueUrl)}${sourceIconUrl ? `&source_icon=${encodeURIComponent(sourceIconUrl)}` : ""}`;

        window.location.replace(fullRedirectUrl)
    }

    const validateToken = (token) => {
        return new Promise(async (resolve) => {
            try {
                jwtService.setSession(token);
                const response = await axios.post(`${LAMBDA_AUTH}/validateAuthToken`)
                resolve(response.data.payload);
            } catch (err) {
                jwtService.setSession(null);
            }
        })
    }

    const loginWithToken = (token) => {

        return new Promise(async (resolve) => {
            try {
                dispatch(
                    authActions.loginWithToken({ Token: token }, function () {
                        setTimeout(() => {
                            jwtService.setSession(token);
                            resolve(token)
                        }, 1000)
                    })
                )
            } catch (err) {
                jwtService.setSession(null);
            }
        })
    }

    const logoutUser = (Token, sourceIconUrl = null) => {
        jwtService.setSession(null);
        dispatch(
            authActions.logout(Token, function () {
                const returnUrl = `${window.location.origin}`;
                const serverUrl = AUTH_URL;
                const appKey = AppKey;
                const fullRedirectUrl = `${serverUrl}/logout?app_key=${encodeURI(appKey)}&return_url=${encodeURIComponent(returnUrl)}${sourceIconUrl ? `&source_icon=${encodeURIComponent(sourceIconUrl)}` : ''}`;

                window.location.replace(fullRedirectUrl)
            })
        )
    }

    return (
        <AgilAuthContext.Provider value={{
            goToLogin,
            validateToken,
            loginWithToken,
            logoutUser
        }}>
            {children}
        </AgilAuthContext.Provider>
    )
}

export const useAgilAuth: any = () => useContext(AgilAuthContext);
