export const SET_INIT_PRODUCT_SYNC = 'SET_INIT_PRODUCT_SYNC'
export const SET_RESET_PRODUCT_SYNC = 'SET_RESET_PRODUCT_SYNC'
export const SET_MATCH_PRODUCT = 'SET_MATCH_PRODUCT'
export const SET_PRODUCT_ACTION = 'SET_PRODUCT_ACTION'
export const SET_MATCH_MODIFIER = 'SET_MATCH_MODIFIER'
export const SET_MODIFIER_ACTION = 'SET_MODIFIER_ACTION'
export const SET_OVERWRITE_PRICE = 'SET_OVERWRITE_PRICE'

export const ACTION_MATCH = 'match'
export const ACTION_CREATE = 'create'
export const ACTION_IGNORE = 'ignore'
