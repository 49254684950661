import React from 'react';
import {
    AnalisisHorarioContainer,
    AnalisisVentaContainer,
    AnalisisVentaIndicadores,
    AntiguedadArea,
    AvatarBagde,
    AvatarContainer,
    CalificacionArea,
    CardClienteContainer,
    ChartInactividadArea,
    DayContainer,
    DayName,
    DayValue,
    FirstSectionContainer,
    HorizontalDiv,
    InactividadContainer,
    InactividadDetalle,
    LabelInactividad,
    LastKpisContainer,
    MainInfoContainer,
    NombreCliente,
    PointsContainer,
    PointsIconArea,
    PointsValue,
    ReputationContainer,
    SecondSectionContainer,
    TipoEntregaArea
} from "./CardCliente.style";
import { Avatar, Tooltip, Button } from "@material-ui/core";
import {
    amber,
    blue,
    blueGrey,
    deepOrange,
    green,
    grey,
    lightBlue,
    orange,
    red,
    teal,
    yellow
} from "@material-ui/core/colors";
import {
    Brightness1,
    Brightness4, Brightness6,
    Brightness7,
    Deck, Help, Info,
    LocalPlay,
    Motorcycle,
    NightsStay,
    Star,
    Stars,
    Store
} from "@material-ui/icons";
import moment from "moment-timezone";
import { withStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core/";
import currencyFormatter from 'currency-formatter';
import currencyFormatterOptions from '../../../config/currencyFormatterOptions.json'
import ReactSpeedometer, { CustomSegmentLabelPosition, Transition } from "react-d3-speedometer"


export const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 14,
    },
}))(Tooltip);

const colorSegmentacion = {
    "INACTIVO": {
        // background: grey[600],
        background: 'linear-gradient(144deg, rgba(167,167,167,1) 0%, rgba(60,60,60,1) 100%)',
        color: "#FFFFFF",
        text: "Inactivo",
        info: "El cliente no ha comprado hace más de 60 días"
    },
    "POTENCIAL": {
        // background: blueGrey[500],
        background: 'linear-gradient(144deg, rgba(67,241,218,1) 0%, rgba(81,119,113,1) 100%)',
        color: "#FFFFFF",
        text: "Potencial",
        info: "Clientes registrados o importados que aún no realizan su primera compra"
    },
    "NUEVO": {
        // background: lightBlue[600],
        background: 'linear-gradient(144deg, rgba(3,155,229,1) 0%, rgba(10,69,97,1) 100%)',
        color: "#FFFFFF",
        text: "Nuevo",
        info: "El cliente realizo su primera compra en el ultimo mes"
    },
    "BRONCE": {
        // background: red[500],
        background: 'linear-gradient(144deg, rgba(224,88,58,1) 0%, rgba(77,40,28,1) 100%)',
        color: "#FFF",
        text: "Bronce",
        info: "Pertenece al 40% de los clientes que han generado el menor ingreso durante los ultimos 12 meses"
    },
    "PLATA": {
        // background: blueGrey[500],
        background: 'linear-gradient(158deg, rgba(169,185,193,1) 0%, rgba(27,80,106,1) 100%)',
        color: "#FFFFFF",
        text: "Plata",
        info: "Pertenece al 60% de los clientes que han generado el mayor ingreso durante los ultimos 12 meses"
    },
    "ORO": {
        // background: yellow[500],
        background: 'linear-gradient(158deg, rgba(255,191,0,1) 30%, rgba(143,113,24,1) 100%)',
        color: "#fff",
        text: "Oro",
        info: "Pertenece al 40% de los clientes que han generado el mayor ingreso durante los ultimos 12 meses"
    },
    "PLATINO": {
        // background: grey[900],
        background: 'linear-gradient(158deg, rgba(0,0,0,1) 0%, rgba(11,115,134,1) 100%)',
        color: "#FFFFFF",
        text: "Platino",
        info: "Pertenece al 20% de los clientes que han generado mayor ingreso durante los ultimos 12 meses"
    },
}

const entregaPreferencia = {
    "Mesa": {
        text: "pide en mesa",
        component: <Deck />
    },
    "Delivery": {
        text: "pide a domicilio",
        component: <Motorcycle />
    },
    "Retiro en Tienda": {
        text: "retira directamente en la tienda",
        component: <Store />
    }
}

const preferenceColorLevel = [teal[900], teal[700], teal[500], teal[400], teal[300], teal[200], teal[100]]

const parseDayName = { lun: "lunes", mar: "martes", mie: "miercoles", jue: "jueves", vie: "viernes", sab: "sabado", dom: "domingo" }


const diffDateToString = (fecha: string) => {
    const ahora = moment();
    const fechaAnterior = moment(fecha);

    let diffTime = ahora.diff(fechaAnterior, "days");
    let diffTimeUnit = "días";

    if (diffTime > 60) {
        diffTime = ahora.diff(fechaAnterior, "month");
        diffTimeUnit = "meses";

        if (diffTime > 12) {
            diffTime = ahora.diff(fechaAnterior, "year");
            diffTimeUnit = "años"
        } else if (diffTime === 1) {
            diffTimeUnit = "mes"
        }
    } else if (diffTime === 1) {
        diffTimeUnit = "día"
    }

    return `${diffTime} ${diffTimeUnit}`;
}


const CardCliente = ({ cliente, programaPuntos, launchAction, formatCurrency }) => {

    const badgeConf = colorSegmentacion[cliente.SegmentacionCompra];
    let nombreCliente = `${cliente.Nombre.toLowerCase()} ${cliente.Apellido ? cliente.Apellido.toLowerCase() : ''}`

    if (nombreCliente.length > 25) {
        nombreCliente = nombreCliente.substring(0, 22) + "...";
    }

    const tiempoAntiguedad = diffDateToString(cliente.FechaPrimerPedido);
    const tiempoUltimoPedido = diffDateToString(cliente.FechaUltimoPedido)
    const preferenciaEntrega = entregaPreferencia[cliente.MetodoEntregaPreferencia]
    const diasCompra = cliente.DiasCompra.split(",")
    let nivelActividad = (cliente.TasaInactividadCliente - 100) * -1;

    if (!cliente.FrecuenciaPromedioCompra) {
        nivelActividad = 0
    }

    const clientToSend = { ...cliente, badgeConf, nombreCliente };

    return (
        <CardClienteContainer>
            <FirstSectionContainer>
                <AvatarContainer>
                    <Avatar src={cliente?.Imagen} />
                    <LightTooltip title={badgeConf?.info}>
                        <AvatarBagde background={badgeConf?.background} color={badgeConf?.color}><Stars className={"star-badge"} /> {badgeConf?.text}</AvatarBagde>
                    </LightTooltip>
                </AvatarContainer>
                <MainInfoContainer>
                    <NombreCliente>
                        {nombreCliente}
                        <LightTooltip title={`Ver la información del cliente`}>
                            <IconButton onClick={launchAction("info", clientToSend)} color='primary' size='medium'>
                                <Info />
                            </IconButton>
                        </LightTooltip>
                    </NombreCliente>
                    <ReputationContainer>
                        <CalificacionArea>
                            <Star className={"star"} />
                            <p>{cliente?.PromedioCalificacionCliente} {cliente?.NivelSatifaccion}</p>
                        </CalificacionArea>
                        <HorizontalDiv />
                        <AntiguedadArea>{tiempoAntiguedad} de antiguedad</AntiguedadArea>
                    </ReputationContainer>
                    <AnalisisVentaContainer>
                        <AnalisisVentaIndicadores>
                            {
                                cliente?.SucursalPreferencia &&
                                <p>Sucursal de Preferencia: {cliente?.SucursalPreferencia} {cliente?.DistanciaMetrosClienteSucursal ? `(${cliente?.DistanciaMetrosClienteSucursal})` : ""}</p>
                            }
                            <p>Últimos 12 meses: {formatCurrency(cliente?.MontoPedidosLY)} ({cliente?.CantidadPedidosLY})</p>
                            <TipoEntregaArea>
                                <LightTooltip title={`Cliente habitualmente ${preferenciaEntrega?.text}. Click para ver más información.`}>
                                    <Button
                                        color='primary'
                                        variant='contained'
                                        size='small'
                                        fullWidth
                                        style={{ display: 'flex', alignItems: 'center' }}
                                        onClick={launchAction("sale", clientToSend)}
                                    >
                                        {preferenciaEntrega?.component}
                                        <span style={{ marginLeft: 10 }}>Historial de compras</span>
                                    </Button>
                                </LightTooltip>
                            </TipoEntregaArea>
                        </AnalisisVentaIndicadores>
                    </AnalisisVentaContainer>
                </MainInfoContainer>
            </FirstSectionContainer>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
                {
                    (cliente?.Importado === 1) && (
                        <div style={{ textAlign: 'center' }}>
                            <p style={{ marginBottom: 0, paddingBottom: 0 }}>Importado desde</p>
                            <p style={{ marginTop: 0, paddingTop: 0, }}>
                                <span
                                    style={{
                                        backgroundColor: '#3f51b5',
                                        color: '#fff',
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                        borderRadius: 100
                                    }}
                                >
                                    {cliente?.BaseImportada}
                                </span>
                            </p>
                        </div>
                    )
                }
            </div>

            <SecondSectionContainer>
                <LightTooltip title={`Comparación entre la cantidad de días desde el último pedido y la frecuencia promedio de compra. Fecha de ultima compra: ${moment(cliente?.FechaUltimoPedido).format("DD/MM/YYYY HH:mm:SS")}`}>
                    <InactividadContainer>
                        <ChartInactividadArea>
                            <ReactSpeedometer
                                fluidWidth={true}
                                minValue={0}
                                maxValue={100}
                                value={nivelActividad}
                                segments={5}
                                segmentColors={[
                                    red[500],
                                    orange[500],
                                    yellow[500],
                                    green[500],
                                    blue[500],
                                ]}
                                needleColor={grey[400]}
                                ringWidth={20}
                                customSegmentLabels={[
                                    {
                                        color: grey[900],
                                        fontSize: "10px"
                                    },
                                    {
                                        color: grey[900],
                                        fontSize: "10px"
                                    },
                                    {
                                        color: grey[900],
                                        fontSize: "10px"
                                    },
                                    {
                                        color: grey[900],
                                        fontSize: "10px"
                                    },
                                    {
                                        color: grey[900],
                                        fontSize: "10px"
                                    },
                                ]}
                                needleTransitionDuration={4000}
                                needleTransition={Transition.easeElastic}
                            />
                        </ChartInactividadArea>
                        <LabelInactividad>Nivel de Actividad: {nivelActividad}%</LabelInactividad>
                        <InactividadDetalle>
                            <p>Frecuencia de Compra: {cliente?.FrecuenciaPromedioCompra} {cliente?.FrecuenciaPromedioCompra === 1 ? 'día' : 'días'}</p>
                            <p>Ultima compra: hace {tiempoUltimoPedido}</p>
                        </InactividadDetalle>
                    </InactividadContainer>
                </LightTooltip>
                <LastKpisContainer>
                    {
                        programaPuntos &&
                        <PointsContainer>
                            <PointsValue>
                                <p className={"title"}>{programaPuntos?.NombrePuntos}</p>
                                <p>{formatCurrency(cliente.PuntosActuales, true)}</p>
                            </PointsValue>
                            <PointsIconArea>
                                <LightTooltip title={`Ver detalle de sus puntos.`}>
                                    <IconButton onClick={launchAction("points", clientToSend)}>
                                        <LocalPlay style={{ color: orange[500], marginRight: 5 }} />
                                    </IconButton>
                                </LightTooltip>
                            </PointsIconArea>
                        </PointsContainer>
                    }
                    <LightTooltip title={`El cliente compra habitualmente los días ${parseDayName[cliente?.DiaHabitualCompra]} y frecuentemente en ${cliente?.JornadaHabitualCompra === "Mediodía" ? "el" : "la"} ${cliente?.JornadaHabitualCompra.toLowerCase()}`}>
                        <AnalisisHorarioContainer>
                            <DayContainer style={{ marginRight: 8 }}>
                                <DayValue color={"none"} >
                                    {
                                        cliente?.JornadaHabitualCompra === "Madrugada" && <NightsStay style={{ color: blueGrey[700] }} />
                                    }
                                    {
                                        cliente?.JornadaHabitualCompra === "Mañana" && <Brightness6 style={{ color: amber[300] }} />
                                    }
                                    {
                                        cliente?.JornadaHabitualCompra === "Mediodía" && <Brightness7 style={{ color: yellow[600] }} />
                                    }
                                    {
                                        cliente?.JornadaHabitualCompra === "Tarde" && <Brightness4 style={{ color: yellow[800] }} />
                                    }
                                    {
                                        cliente?.JornadaHabitualCompra === "Noche" && <Brightness1 style={{ color: grey[700] }} />
                                    }
                                </DayValue>
                                <DayName>{cliente?.JornadaHabitualCompra}</DayName>
                            </DayContainer>
                            <DayContainer>
                                <DayValue color={preferenceColorLevel[diasCompra.indexOf("lun")]} />
                                <DayName>L</DayName>
                            </DayContainer>
                            <DayContainer>
                                <DayValue color={preferenceColorLevel[diasCompra.indexOf("mar")]} />
                                <DayName>M</DayName>
                            </DayContainer>
                            <DayContainer >
                                <DayValue color={preferenceColorLevel[diasCompra.indexOf("mie")]} />
                                <DayName>M</DayName>
                            </DayContainer>
                            <DayContainer >
                                <DayValue color={preferenceColorLevel[diasCompra.indexOf("jue")]} />
                                <DayName>J</DayName>
                            </DayContainer>
                            <DayContainer >
                                <DayValue color={preferenceColorLevel[diasCompra.indexOf("vie")]} />
                                <DayName>V</DayName>
                            </DayContainer>
                            <DayContainer color={preferenceColorLevel[cliente.DiasCompra.indexOf("sab")]}>
                                <DayValue />
                                <DayName>S</DayName>
                            </DayContainer>
                            <DayContainer >
                                <DayValue color={preferenceColorLevel[cliente.DiasCompra.indexOf("dom")]} />
                                <DayName>D</DayName>
                            </DayContainer>
                        </AnalisisHorarioContainer>
                    </LightTooltip>
                </LastKpisContainer>
            </SecondSectionContainer>
        </CardClienteContainer>
    )
}

export default CardCliente;