import types from "./types"

const INITIAL_STATE = {
    message: '',
    AppKey: '',
    Token: '',
    auth: {
        nacionalidad: {}
    },
    payload: {
        privilegiosPerfiles: [],
        usuarioPerfiles: [],

        usuarioPerfil: {
            sucursal: {
                zonaHoraria: {
                    Zona: 'America/Santiago',
                },
                comercio: {
                    disenos: [],
                    nacionalidad: {
                        Moneda: 'CLP'
                    },
                    urls: []
                }
            }
        },
        privilegiosPerfil: {
            privilegios: []
        },
    },
    paymentDetail: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.AUTHENTICATE_SUCCESS:
            return {
                ...state,
                ...action.payload
            }
        case types.LOGOUT_SUCCESS: {
            localStorage.removeItem("paymentDetail")
            return INITIAL_STATE
        }
        case types.SET_PAYMENT_DETAIL: {
            state.paymentDetail = action.payload
            return {
                ...state
            }
        }
        default:
            return state
    }
}
