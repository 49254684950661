import React from 'react'
import { Loading } from "./Loading.style";

const LoadingClient = ({ title }) => {
    return (
        <Loading>
            <div className={"image-container"}>
                <img src={"https://s3.us-east-2.amazonaws.com/agil.public/general/user_profile_loading.gif"} />
            </div>
            <p className={"title"}>{title}</p>
        </Loading>
    )
}

export default LoadingClient;