import types from "./types";

export default {
  loginWithToken: function (payload, callback) {
    return {
      type: types.LOGIN_WITH_TOKEN,
      payload,
      callback
    }
  },
  authenticate: function (payload, callback = () => { }) {
    return {
      type: types.AUTHENTICATE,
      payload,
      callback
    }
  },
  logout: function (payload, callback = () => { }) {
    return {
      type: types.LOGOUT,
      payload,
      callback
    }
  },

  settPaymentDetail: function (payload, callback = () => { }) {
    return {
      type: types.SET_PAYMENT_DETAIL,
      payload,
      callback
    }
  },
}





