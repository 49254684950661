import React, {useEffect, useState} from 'react'
import {
    InsightLoading,
    InsightPaginatorContainer,
    InsightsBody,
    InsightsContainer,
    InsightsHeader,
    InsightsListContainer, InsightsNoData,
    InsightsSearchContainer
} from "./ProductsInsights.style";
import {TextField} from "@material-ui/core";
import {Pagination} from "@material-ui/lab";
import CardProductInsight from "./CardProductInsight/CardProductInsight";
import axios from "axios";
import config from "../../config"
import {useSelector} from "react-redux";
import currencyFormatterOptions from "../../config/currencyFormatterOptions.json";
import currencyFormatter from 'currency-formatter';
import ProductDetailModal from "./ProductDetailModal";

type IProductInsight = { IdProducto: number }

const ProductsInsights = () => {

    const auth = useSelector<any, any>(state => state.auth);
    const [searchText, setSearchText] = useState("");
    const [products, setProducts] = useState([] as Array<IProductInsight>);
    const [page, setPage] = useState(1);
    const [numPages, setNumPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [showProductModal, setShowProductModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    useEffect(() => {
        getProductos()
    }, [page])

    useEffect(() => {
        const timer = setTimeout(() => {
            getProductos(true)
        }, 800)

        return () => {
            clearTimeout(timer);
        }
    }, [searchText])


    const getProductos = async (resetPage = false) => {
        setLoading(true);

        let pageNum = page;
        if (resetPage) {
            setPage(1);
            pageNum = 1;
        }

        const response = await axios.get(`${config.LAMBDA_ALIADOS_13489AA}/insights/getProductsInsights/${auth.payload.usuarioPerfil.sucursal.IdSucursal.toString()}?page=${pageNum}${searchText ? `&searchText=${encodeURI(searchText)}` : ""}`);

        const {
            pagination,
            productos
        } = response.data;

        setProducts(productos);
        setNumPages(pagination.pages);

        setLoading(false);
    }

    const formatCurrency = (value, hideSymbol = false) => {

        if (hideSymbol) {
            return currencyFormatter.format(value, { ...currencyFormatterOptions[auth.payload.usuarioPerfil.sucursal.comercio.nacionalidad.Moneda], symbol: "" })
        }
        return currencyFormatter.format(value, { ...currencyFormatterOptions[auth.payload.usuarioPerfil.sucursal.comercio.nacionalidad.Moneda] })
    }


    const launchProductModal = (product) => {
        setShowProductModal(true);
        setSelectedProduct(product)
    }

    if(loading){
        return (
            <InsightLoading>
                <div className={"image-container"}>
                    <img src={"https://s3.us-east-2.amazonaws.com/agil.public/general/search_products.gif"} />
                </div>
                <p className={"title"}>Cargando Productos ...</p>
            </InsightLoading>
        )
    }

    return (
        <InsightsContainer>
            <InsightsHeader>
                <InsightsSearchContainer>
                    <TextField
                        variant="outlined"
                        label="Buscar Productos"
                        placeholder="Escribe nombre del producto"
                        value={searchText}
                        onChange={(evt : any) => setSearchText(evt.target.value)}
                        fullWidth
                        autoFocus
                    />
                </InsightsSearchContainer>
            </InsightsHeader>
            <InsightsBody>
                <InsightsListContainer direction={"row"}>

                    {
                        products.map((p) => {
                            return (
                                <CardProductInsight
                                    key={p.IdProducto}
                                    product={p}
                                    formatCurrency={formatCurrency}
                                    launchProductModal={launchProductModal}
                                />)
                        })
                    }
                    {
                        products.length <= 0 &&
                            <InsightsNoData>
                                <img src={"https://s3.us-east-2.amazonaws.com/agil.public/general/notfound.gif"} />
                                {
                                    searchText !== "" ?
                                        <p>¡No se han encontrado productos que cumplan con el criterio de búsqueda!</p>
                                        :
                                        <p>¡No existe registro de productos!</p>
                                }
                            </InsightsNoData>
                    }
                </InsightsListContainer>
            </InsightsBody>
            <InsightPaginatorContainer>
                <Pagination count={numPages} page={page} onChange={(evt, val) => { setPage(val) }} />
            </InsightPaginatorContainer>

            <ProductDetailModal
                open={showProductModal}
                product={selectedProduct}
                onClose={() => setShowProductModal(false)}
            />
        </InsightsContainer>
    )
}

export default ProductsInsights